import React, { useState, useContext, useEffect } from "react";
import Loading from "../Loading";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import styled from "styled-components";
import Button from "../styled/Button";
import Pricing from "components/dashboard/Pricing";
import { AuthContext } from "components/Auth";
import PatientNav from "./PatientNav";
import { Navigate } from "react-router-dom";
import UserProfileSettings from "./UserProfileSettings";

const InputWrapper = styled.div`
  border-top: ${(props) => (props.top ? "0.55px solid #c8c7cc" : "none")};
  padding-top: ${(props) => (props.top ? "10px" : "0")};
  border-bottom: 0.55px solid #c8c7cc;
  margin-bottom: 10px;
`;

const TwoColumn = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
  /* color: #000; */
`;

const PatientBilling = ({ userData }) => {
  const { user, subscription, userLoading, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState();
  const [userSettingsOpen, setUserSettingsOpen] = useState(false);

  const sendToPortal = async () => {
    setLoading("portal");

    const functionRef = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      returnUrl: window.location.href,
    });
    window.location.assign(data.url);
  };

  const openUserSettings = () => {
    setUserSettingsOpen(!userSettingsOpen);
  };

  const handleLogout = async () => {
    setLoading("logout");
    await logout();
    return <Navigate to="/login/" />;
  };

  // if (!user) {
  //   getUserFromUid(uid).then((user) => {
  //     db.collection("locations")
  //       .doc(user.location)
  //       .get()
  //       .then((l) => {
  //         setLocation(l.data());
  //         console.log(l.data());
  //       });
  //   });
  // }

  return (
    <>
      <PatientNav />
      <div className="inner patient">
        <h1>Account</h1>

        {subscription ? (
          <>
            <p style={{ marginBottom: "2rem" }}>
              You're currently logged in as {user.email}.
            </p>
            <Button onClick={openUserSettings} disabled={loading}>
              Update Patient Medical Profile
            </Button>
            <Button type="submit" onClick={sendToPortal} disabled={loading}>
              {loading === "portal" ? <Loading /> : "Manage Subscription"}
            </Button>
            <Button onClick={handleLogout} disabled={loading}>
              {loading === "logout" ? <Loading /> : "Log Out"}
            </Button>
            <UserProfileSettings
              user={user}
              data={userData}
              visible={userSettingsOpen}
              close={() => setUserSettingsOpen(false)}
            />
          </>
        ) : (
          <Pricing uid={user.uid} subscription={subscription} />
        )}
      </div>
    </>
  );
};

export default PatientBilling;
