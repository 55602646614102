import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { FaCommentDots } from "react-icons/fa";
import ChatbotWrapper from "../chatbot/ChatbotWrapper";
import DashboardWrapper from "./DashboardWrapper";

// function getMobileOperatingSystem() {
//   var userAgent = navigator.userAgent || navigator.vendor || window.opera;

//   // Windows Phone must come first because its UA also contains "Android"
//   if (/windows phone/i.test(userAgent)) {
//     return "Windows Phone";
//   }

//   if (/android/i.test(userAgent)) {
//     return "Android";
//   }

//   // iOS detection from: http://stackoverflow.com/a/9039885/177710
//   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
//     return "iOS";
//   }

//   return "unknown";
// }

const Circle = styled.a`
  background-color: #1b4584;
  border-radius: 50%;
  min-height: 55px;
  min-width: 55px;
  bottom: 20px;
  right: 20px;
  position: fixed;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  z-index: 99;
  cursor: pointer;

  &:hover {
    background-color: #2b77ad;
  }
  svg {
    display: inline-block;
    transform: scale(1.8);
    top: 19px;
    position: relative;
    color: white;
  }
`;
const ContactBoxWrapper = styled.div`
  width: 100vw;
  max-height: 100vh;
  z-index: 5000000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  transform: ${({ $show }) => ($show ? "translateY(0)" : "translateY(-110%)")};
  transition: transform 0.3s ease-in-out;
`;
const CTAAnimation = keyframes`
  0% { transform: translateX(105%); }
  70% { transform: translateX(105%); }
  100% { transform: translateX(0%); }
`;

const ChatbotCTA = styled.div`
  min-width: 55px;
  bottom: 20px;
  right: 80px;
  position: fixed;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  z-index: 99;
  overflow: hidden;
  pointer-events: none;

  div {
    background-color: #fff;
    border-radius: 5px;
    font-size: 12px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 18px 0 rgba(0, 0, 0, 0.19);
    padding: 10px;
    animation: ${({ loading }) => (loading ? null : CTAAnimation)};
    animation-duration: 1s;
    z-index: 999;
  }
`;

const ContactBox = ({ show, setShow }) => {
  return (
    <ContactBoxWrapper $show={show}>
      <DashboardWrapper>
        <ChatbotWrapper setShow={setShow} />
      </DashboardWrapper>
    </ContactBoxWrapper>
  );
};

const ContactCircle = ({ loading }) => {
  const [showPopup, setShowPopup] = useState(false);

  // const [contactHref, setContactHref] = useState("");
  // const contact_number = "5017625114";
  // const default_message = "this is a test";
  // let newProps = {};

  // useEffect(() => {
  //   const os = getMobileOperatingSystem();
  //   if (/Mobi|Android/i.test(navigator.userAgent)) {
  //     var seperator = "?";
  //     if (os == "iOS") {
  //       seperator = "&";
  //     } else {
  //       seperator = "?";
  //     }
  //     setContactHref(
  //       "sms:+" +
  //         contact_number +
  //         seperator +
  //         "body=" +
  //         encodeURIComponent(default_message)
  //     );
  //   } else {
  //     setContactHref("#");
  //   }
  // });

  return (
    <>
      <ContactBox show={showPopup} setShow={setShowPopup} />

      {/* <ChatbotCTA $loading={loading}>
        <div>Get instant diagnoses using AI!</div>
      </ChatbotCTA> */}
      <Circle
        // href={contactHref}
        onClick={() => {
          setShowPopup(!showPopup);
        }}
      >
        <FaCommentDots />
      </Circle>
    </>
  );
};

export default ContactCircle;
