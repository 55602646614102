import { useContext, useEffect, useState } from "react";
import { MainContainer } from "@chatscope/chat-ui-kit-react";
import firebase from "firebase/compat/app";

import logo from "logo-icon.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TextLink from "../styled/TextLink";
import Button from "../styled/Button";
import Loading from "components/Loading";
import { db } from "../../firebase";
import { differenceInDays } from "date-fns";
import Chatbot from "./Chatbot";
import { AuthContext } from "components/Auth";
import PatientNav from "./PatientNav";

function parse(text) {
  var bold = /\*\*(.*?)\*\*/gm;
  var source = /【.+†source】/g;
  var html = text.replace(bold, "<strong>$1</strong>").replace(source, "");
  return html;
}

const OptionsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 100px;
  text-align: center;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  img {
    max-width: 84px;
    margin: 0 auto;
  }

  h3 {
    text-transform: uppercase;
    margin-bottom: 0;
  }

  div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }
`;

const PatientDashboard = ({ userData }) => {
  const { user, subscription, userLoading } = useContext(AuthContext);
  const [messages, setMessages] = useState();
  const [thread, setThread] = useState();
  const [loading, setLoading] = useState(false);
  const [rateLimited, setRateLimited] = useState(false);

  const limit = 120;

  const newThread = () => {
    setThread();
    setMessages([]);

    const timestamp = Date.now();
    db.collection("chat")
      .doc(user.uid)
      .collection("threads")
      .doc(String(timestamp))
      .set({ messages: [], timestamp })
      .then(() => {
        console.log("New thread created.");
      });
  };

  const sendToPortal = async () => {
    setLoading(true);

    const functionRef = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      returnUrl: window.location.href,
    });
    window.location.assign(data.url);
  };

  useEffect(() => {
    if (!user) return;

    db.collection("chat")
      .doc(user.uid)
      .collection("threads")
      .onSnapshot((querySnapshot) => {
        const threads = [];

        querySnapshot.forEach((doc) => {
          threads.push({ data: doc.data(), id: doc.id });
        });

        if (threads.length === 0) {
          setMessages([]);
          return;
        }

        const currentThread = threads.sort(
          (a, b) => Number(a.id) - Number(b.id)
        )[threads.length - 1];
        const currentThreadId = currentThread.id;

        setThread(currentThreadId);

        const newMessages = currentThread.data.messages;
        const filteredMessages = newMessages.map((message) => ({
          ...message,
          message: parse(message.message),
          direction: message.sender === "HealthDesk" ? "incoming" : "outgoing",
        }));

        const totalMessages = [];
        threads.map((thread) => {
          totalMessages.push(...thread.data.messages);
          return;
        });

        const messagesToday = totalMessages.filter((message) => {
          return (
            differenceInDays(message.created, Math.round(Date.now() / 1000)) ===
            0
          );
        }).length;

        if (messagesToday >= limit) {
          setRateLimited(true);
        }

        setMessages(filteredMessages);
      });
  }, [user]);

  if (!user) {
    return <ChatbotSignup />;
  }

  if (!userLoading && subscription === undefined) {
    return (
      <>
        <PatientNav />
        <OptionsWrapper>
          <p style={{ fontSize: "15px", fontWeight: "bold" }}>
            You're not currently subscribed.
          </p>
          <p style={{ fontSize: "15px", marginBottom: "25px" }}>
            To use the Medical Superintelligence, please sign up for a
            subscription below.
          </p>
          <Link to="/dashboard/account">
            <Button>Subscribe</Button>
          </Link>
        </OptionsWrapper>
      </>
    );
  }

  if (!userLoading && subscription === null) {
    return (
      <>
        <PatientNav />
        <OptionsWrapper>
          <p style={{ fontSize: "15px", fontWeight: "bold" }}>
            Your subscription has expired.
          </p>
          <p style={{ fontSize: "15px", marginBottom: "25px" }}>
            To continue using the Medical Superintelligence, please sign up for
            a subscription below.
          </p>
          <Button type="submit" onClick={sendToPortal} disabled={loading}>
            {loading ? <Loading /> : "Manage Subscription"}
          </Button>
        </OptionsWrapper>
      </>
    );
  }

  if (messages == null)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <Loading page />
      </div>
    );

  return (
    <>
      <PatientNav messages={messages} newThread={newThread} />
      <MainContainer>
        <Chatbot
          thread={thread}
          messages={messages}
          setMessages={setMessages}
          user={user}
          limit={limit}
          rateLimited={rateLimited}
          userData={userData}
        />
      </MainContainer>
    </>
  );
};

export default PatientDashboard;

const ChatbotSignup = () => {
  return (
    <OptionsWrapper>
      <img src={logo} alt="HealthDesk Logo" />
      <h3>HealthDesk</h3>
      <p style={{ fontWeight: "bold" }}>Health Care's Help Desk</p>
      <p>
        Get expert-level medical recommendations from home. With HealthDesk's
        Medical Superintelligence, you can describe your symptoms, get advice on
        medications, and more.
      </p>
      <p>
        Sign up now for a free 24-hour free trial. No credit card necessary.
      </p>
      <Link to="/register">
        <Button>Sign up now</Button>
      </Link>
      <TextLink $center to="/login">
        Login
      </TextLink>
    </OptionsWrapper>
  );
};
