import React, { useEffect, useRef, useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  Button as ChatButton,
  ChatContainer,
  ConversationHeader,
} from "@chatscope/chat-ui-kit-react";
import firebase from "firebase/compat/app";

import logo from "logo-icon.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TextLink from "../styled/TextLink";
import Button from "../styled/Button";
import Loading from "components/Loading";
import { db } from "../../firebase";
import { differenceInDays } from "date-fns";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa6";
import UserProfileSettings from "./UserProfileSettings";

const OptionsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 50px;
  text-align: center;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  img {
    max-width: 84px;
    margin: 0 auto;
  }

  h3 {
    text-transform: uppercase;
    margin-bottom: 0;
  }

  div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }

  .disclaimer {
    color: #1b4584;
    display: block;
    font-size: 0.75rem;
    position: absolute;
    max-width: 480px;
    margin: auto;
    bottom: 0;
    left: 0;
    right: 0;

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
    }
  }
`;

const ChatWrapper = styled(ChatContainer)`
  .cs-message--incoming .cs-message__content {
    background-color: #eee;
  }

  .cs-message--outgoing .cs-message__content {
    color: #fff;
    background-color: #117aca;
  }

  .cs-button--send {
    color: #117aca;
  }

  .cs-message-input__content-editor-wrapper,
  .cs-message-input__content-editor {
    background-color: #eee;
  }
`;

const MessageWrapper = styled.div`
  button {
    background: transparent;
    border: none;
    opacity: 0.5;
    padding-top: 0.375rem;
    padding-right: 0.625rem;
    padding-bottom: 0.25rem;
    cursor: pointer;
  }

  button:hover {
    opacity: 1;
  }

  font-size: 12px;
  color: #1b4584;
  line-height: 27px;
  height: 27px;
`;

const Chatbot = ({
  thread,
  messages,
  setMessages,
  user,
  limit,
  rateLimited,
  userData,
}) => {
  const [isTyping, setIsTyping] = useState(false);
  const [submitInfo, setSubmitInfo] = useState(true);
  const [reported, setReported] = useState([]);

  const handleFeedback = async (message, id, positive) => {
    const newFeedback = {
      feedback: firebase.firestore.FieldValue.arrayUnion({
        rating: positive ? "positive" : "negative",
        message: message,
      }),
      thread: thread,
    };

    db.collection("feedback")
      .doc(user.uid)
      .set(newFeedback, { merge: true })
      .then(() => {
        console.log("Feedback has been posted.");
      });

    setReported([...reported, id]);
  };

  const handleSendRequest = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setIsTyping(true);

    try {
      await processMessageToChatGPT(newMessage);
    } catch (error) {
      console.error("Error processing message:", error);
    }
  };

  async function processMessageToChatGPT(message) {
    if (!user) return;

    let newMessage = {
      messages: firebase.firestore.FieldValue.arrayUnion({
        sender: message.sender,
        message: message.message,
        created: Math.round(Date.now() / 1000),
      }),
    };

    if (submitInfo) {
      if (userData?.profile) newMessage.user = {};
      if (userData?.profile?.sex) newMessage.user.sex = userData.profile?.sex;
      if (userData?.profile?.age) newMessage.user.age = userData.profile?.age;
    }

    if (thread) {
      db.collection("chat")
        .doc(user.uid)
        .collection("threads")
        .doc(thread)
        .set(newMessage, { merge: true })
        .then(() => {
          console.log("Message has been posted.");
        });
    } else {
      db.collection("chat")
        .doc(user.uid)
        .collection("threads")
        .doc(String(Date.now()))
        .set(newMessage, { merge: true })
        .then(() => {
          console.log("Message has been posted.");
        });
    }
  }

  useEffect(() => {
    if (messages?.length > 0) {
      if (messages[messages.length - 1].sender === "HealthDesk")
        setIsTyping(false);
    }
  }, [messages]);

  return (
    <>
      {messages?.length === 0 ? (
        <Options
          sendMessage={handleSendRequest}
          user={user}
          userData={userData}
          submitInfo={submitInfo}
          setSubmitInfo={setSubmitInfo}
        />
      ) : null}
      <ChatWrapper>
        <ConversationHeader style={{ height: "45px" }} />
        <MessageList
          typingIndicator={
            isTyping ? <TypingIndicator content="HealthDesk is typing" /> : null
          }
        >
          {messages?.map((message, i) => {
            return (
              <React.Fragment key={i}>
                <Message model={message} />
                {message.sender === "HealthDesk" ? (
                  reported.includes(i) ? (
                    <MessageWrapper>
                      Thank you for your feedback.
                    </MessageWrapper>
                  ) : (
                    <MessageWrapper>
                      <button
                        onClick={() => {
                          handleFeedback(message.message, i, true);
                        }}
                      >
                        <FaThumbsUp size={18} color="#117ACA" />
                      </button>
                      <button
                        onClick={() => {
                          handleFeedback(message.message, i, false);
                        }}
                      >
                        <FaThumbsDown size={18} color="#117ACA" />
                      </button>
                    </MessageWrapper>
                  )
                ) : null}
              </React.Fragment>
            );
          })}
        </MessageList>
        <MessageInput
          disabled={rateLimited}
          placeholder={
            rateLimited
              ? `You have used up your ${limit} messages/day.`
              : "Message HealthDesk"
          }
          attachButton={false}
          onSend={handleSendRequest}
          style={{ zIndex: 99999999999, position: "relative" }}
        />
      </ChatWrapper>
    </>
  );
};

export default Chatbot;

const Options = ({
  sendMessage,
  user,
  userData,
  submitInfo,
  setSubmitInfo,
}) => {
  const [userSettingsOpen, setUserSettingsOpen] = useState(false);

  const openUserSettings = () => {
    setUserSettingsOpen(!userSettingsOpen);
  };

  return (
    <>
      <UserProfileSettings
        user={user}
        data={userData}
        visible={userSettingsOpen}
        close={() => setUserSettingsOpen(false)}
      />
      <OptionsWrapper>
        <img src={logo} alt="HealthDesk Logo" />
        <h3>HealthDesk</h3>
        <p style={{ fontWeight: "bold" }}>Health Care's Help Desk</p>
        <div>
          <ChatButton
            onClick={() => sendMessage("Use a different language")}
            border
          >
            Use a different language
          </ChatButton>
          <ChatButton onClick={openUserSettings} border>
            Update Patient Medical Profile
          </ChatButton>
          <ChatButton
            onClick={() => sendMessage("Help with medication costs")}
            border
          >
            Help with Medication Costs
          </ChatButton>
          <ChatButton
            onClick={() => sendMessage("Find An Emergency Room")}
            border
          >
            Find An Emergency Room
          </ChatButton>
        </div>
        <div className="disclaimer">
          <p>
            <label>
              <input
                type="checkbox"
                checked={submitInfo}
                onChange={() => setSubmitInfo(!submitInfo)}
              />
              Your patient profile will be submitted with these queries.
            </label>
          </p>
          <p>
            HLTHDSK Medical SuperIntelligence is expert-curated artificial
            intelligence. It can make mistakes and cannot provide health care.
            Verify all medical information with a health care professional.
          </p>
        </div>
      </OptionsWrapper>
    </>
  );
};

const ChatbotSignup = () => {
  return (
    <OptionsWrapper>
      <img src={logo} alt="HealthDesk Logo" />
      <h3>HealthDesk</h3>
      <p style={{ fontWeight: "bold" }}>Health Care's Help Desk</p>
      <p>
        Get expert-level medical recommendations from home. With HealthDesk's
        Medical Superintelligence, you can describe your symptoms, get advice on
        medications, and more.
      </p>
      <p>
        Sign up now for a free 24-hour free trial. No credit card necessary.
      </p>
      <Link to="/register">
        <Button>Sign up now</Button>
      </Link>
      <TextLink $center to="/login">
        Login
      </TextLink>
    </OptionsWrapper>
  );
};
