import React, { useEffect, useState } from "react";
import Legend from "./Legend";
import seat from "../images/chair.png";
import seatFilled from "../chair-filled.png";
import clock from "../images/clock.png";

import Wrapper from "./styled/Wrapper";
import Label from "./styled/Label";

const SeatRating = ({
  register,
  schedule,
  value,
  date,
  defaultTime,
  getValues,
}) => {
  const [selected, setSelected] = useState();
  const options = [30, 60, 120, 150, 180, 240, 360];

  useEffect(() => {
    if (schedule) {
      setSelected(getValues("time-" + date));
    }
  }, []);

  useEffect(() => {
    if (value) setSelected(undefined);
  }, [value]);

  return (
    <>
      <Wrapper>
        {options.map((option) => {
          const name = schedule ? "time-" + date : "time";

          return (
            <Label
              $schedule={schedule}
              selected={selected === option || defaultTime}
              key={option}
            >
              <div>
                <img
                  src={
                    option <= (selected || value || defaultTime)
                      ? seatFilled
                      : seat
                  }
                  alt={
                    option <= (selected || value || defaultTime)
                      ? "Seat Filled"
                      : "Empty Seat"
                  }
                />
                <input
                  type="radio"
                  {...(register &&
                    register(name, {
                      onChange: (e) => {
                        setSelected(e.target.value);
                      },
                    }))}
                  value={option}
                  defaultChecked={option === defaultTime}
                />
              </div>
            </Label>
          );
        })}
        <Label>
          <div>
            <img src={clock} alt="" />
          </div>
        </Label>
      </Wrapper>
      <Legend schedule={schedule} />
    </>
  );
};

export default SeatRating;
