import { useContext } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { MainContainer } from "@chatscope/chat-ui-kit-react";

import styled from "styled-components";
import CloseButton from "../styled/CloseButton";
import Chatbot from "./Chatbot";
import PatientDashboard from "./PatientDashboard";
import { FaTimes } from "react-icons/fa";

const CloseWrapper = styled.div`
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  padding: 12px;
  font-size: 30px;
  line-height: 20px;
  cursor: pointer;
  z-index: 999;
`;

const ChatbotWrapper = ({ messages, setShow }) => {
  return (
    <>
      {setShow ? (
        <CloseWrapper onClick={() => setShow(false)}>
          <FaTimes size="18" color="#1B4584" />
        </CloseWrapper>
      ) : null}
      <PatientDashboard />
    </>
  );
};

export default ChatbotWrapper;
