import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const FilterWrapper = styled.div`
  display: table;
  margin: 0 auto;

  label {
    display: inline;
    margin: 0px 15px 0px 15px;
    align-items: center;
    margin-top: 5px;

    input {
      margin-top: 0;
    }
  }
`;

const Popup = styled.p`
  margin-bottom: 0 !important;
  font-weight: 400 !important;

  a {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const Filters = ({ setFilter, filter, data, vaccine }) => {
  const filters = vaccine
    ? [
        { value: "all", name: "All" },
        { value: "clinic", name: "Clinic / Immediate Care" },
        { value: "emergency", name: "Emergency" },
      ]
    : [
        { value: "all", name: "All" },
        { value: "clinic", name: "Clinic" },
        { value: "emergency", name: "Hospital" },
      ];

  const showClinics = window.location.host.split(".")[0] === "clinics";

  return (
    <>
      <FilterWrapper>
        {filters.map(({ value, name }) => (
          <label htmlFor={value} key={value}>
            <input
              type="radio"
              id={value}
              name="filter"
              value={value}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              defaultChecked={
                showClinics ? value === "clinic" : value === "all"
              }
            />
            {name}
          </label>
        ))}
      </FilterWrapper>

      {vaccine ? null : (
        <>
          {filter === "1" && (
            <Popup>
              <Link to="register">
                Click here to register an Urgent Care Facility.
              </Link>
            </Popup>
          )}

          {filter === "healthcard" && (
            <Popup>
              Safety, savings, and more: The HealthCard lets every healthcare
              visit start on the same page: Yours.{" "}
              <a
                href="https://interlockhealth.com/hclm/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to learn more.
              </a>
            </Popup>
          )}

          {filter === "videocare" && (
            <Popup>
              Pre(ER): A telehealth connection to the ER
              <br />
              <a
                href="https://interlockhealth.com/vclm/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facilities: Learn how to offer Pre(ER)
              </a>
            </Popup>
          )}
        </>
      )}
    </>
  );
};

export default Filters;
