import React, { useEffect } from "react";
import Loading from "./Loading";
import Columns from "./styled/Columns";
import Button from "./styled/Button";
import Text from "./styled/Text";
import TextAnchor from "./styled/TextAnchor";
import Error from "./styled/Error";

export default ({ firebase, loading, submitted, resendEmail, dbError }) => {
  useEffect(() => {
    const timer = setInterval(() => {
      firebase.auth().currentUser.reload();

      if (firebase.auth().currentUser.emailVerified) {
        window.location.reload();
      }
    }, 1000);
    return () => clearInterval(timer);
  });

  return (
    <>
      <p>
        Your application has been submitted. You should have been sent a
        registration confirmation email. Please verify your email address by
        clicking the link in the email.
      </p>
      <p>
        If you haven't received your email after 30 minutes, and it's not in
        your spam folder, click to resend your email below.
      </p>

      <div>
        <Button disabled={loading} onClick={resendEmail}>
          {loading ? <Loading /> : "Resend Verification Email"}
        </Button>
        {submitted && (
          <Text $center>Your verification email has been resent.</Text>
        )}
        {dbError && <Error>Could not resend email. Try again later.</Error>}
        <TextAnchor $center href="mailto:support@hlthdsk.com">
          If you're having any problems registering, please contact us.
        </TextAnchor>
      </div>
    </>
  );
};
