import React, { useEffect } from "react";
import styled from "styled-components";
import EmergencySearch from "../EmergencySearch";
import InputField from "../styled/InputField";
import Input from "../styled/Input";
import Error from "../styled/Error";
import Search from "components/Search";

const InputWrapper = styled.div`
  border-top: ${({ $top }) => ($top ? "0.55px solid #c8c7cc" : "none")};
  padding-top: ${({ $top }) => ($top ? "10px" : "0")};
  border-bottom: 0.55px solid #c8c7cc;
  margin-bottom: 10px;
`;

const TwoColumn = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
  /* color: #000; */
`;

const FormFacility = ({
  register,
  unregister,
  setHospitalId,
  setCoords,
  setAddress,
  setValue,
  watch,
  errors,
  control,
}) => {
  const watchFacility = watch("facility", "");

  return (
    <>
      <InputWrapper>
        <Label>This facility is a:</Label>
        <TwoColumn>
          <input
            type="radio"
            id="hospital"
            value="hospital"
            {...(register &&
              register("facility", {
                required: "Facility type required.",
              }))}
          />
          <Label htmlFor="hospital" $large>
            Emergency Department (must be open 24 hours)
          </Label>
        </TwoColumn>
        <TwoColumn>
          <input
            type="radio"
            id="urgent-care"
            value="urgent-care"
            {...(register &&
              register("facility", {
                required: "Facility type required.",
              }))}
          />
          <Label htmlFor="urgent-care" $large>
            Clinic / Immediate Care
          </Label>
        </TwoColumn>
        {/* <TwoColumn>
          <input
            type="radio"
            id="pharmacy"
            value="pharmacy"
            {...(register &&
              register("facility", {
                required: "Facility type required.",
              }))}
          />
          <Label htmlFor="pharmacy" $large>
            Pharmacy
          </Label>
        </TwoColumn> */}

        {/* Removed because of the change with ticket 279
                <TwoColumn>
                    <input
                        type="radio"
                        name="facility"
                        id="regional"
                        value="regional"
                        ref={register({
                            required: "Facility type required.",
                        })}
                    />
                    <Label htmlFor="regional" $large>
                        Regional System
                    </Label>
                    </TwoColumn>*/}
        {errors.facility && <Error>{errors.facility.message}</Error>}
      </InputWrapper>

      {watchFacility === "hospital" ? (
        <>
          <InputWrapper>
            <Label htmlFor="emergency_location">Facility Name/Address</Label>
            <EmergencySearch
              control={control}
              setValue={setValue}
              setHospitalId={setHospitalId}
            />
            {errors.emergency_location && (
              <Error>{errors.emergency_location.message}</Error>
            )}
          </InputWrapper>

          <p>Apply to:</p>
          <ul>
            <li>Create Virtual Queue(s)</li>
            <li>Manage Virtual Registration</li>
            <li>Manage the facility map listing</li>
            <li>$1,499/ month</li>
            <li>$6,000 annually </li>
            <li>Includes onboarding conference call </li>
          </ul>
        </>
      ) : null}

      {watchFacility === "urgent-care" ? (
        <div>
          <InputWrapper>
            <Label htmlFor="location">Facility Name</Label>
            <Input
              id="location"
              type="text"
              {...(register &&
                register("location", {
                  required: "Facility name is required.",
                }))}
            />
            {errors.location && <Error>{errors.location.message}</Error>}
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="address">Facility Address</Label>
            <Search
              setCoords={setCoords}
              setAddress={setAddress}
              addressField
            />
            {errors.address && <Error>{errors.address.message}</Error>}
          </InputWrapper>

          <p>Apply to:</p>
          <ul>
            <li>Create Virtual Queue(s)</li>
            <li>Manage Virtual Registration</li>
            <li>Manage the facility map listing</li>
            <li>$1,499/ month</li>
            <li>$6,000 annually </li>
            <li>Includes onboarding conference call </li>
          </ul>
        </div>
      ) : null}

      {/* {watchFacility === "pharmacy" ? (
        <React.Fragment>
          <InputWrapper>
            <Label htmlFor="location">Facility Name</Label>
            <Input
              id="location"
              type="text"
              {...(register &&
                register("location", {
                  required: "Facility name is required.",
                }))}
            />
            {errors.location && <Error>{errors.location.message}</Error>}
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="address">Facility Address</Label>
            <Search setCoords={setCoords} setAddress={setAddress} addressField/>
            {errors.address && <Error>{errors.address.message}</Error>}
          </InputWrapper>
          <p>Apply to:</p>
          <ul>
            <li>Create Virtual Queue</li>
            <li>Manage Virtual Registration </li>
            <li>Manage the facility map listing</li>
            <li>$1,499/ month</li>
            <li>$6,000 annually </li>
            <li>Includes onboarding conference call </li>
          </ul>
        </React.Fragment>
      ) : 
      <InputWrapper>
              
                    // <Label htmlFor="location">Facility Name/Address</Label>
                    // <EmergencySearch
                    //     setHospitalId={setHospitalId}
                    //     control={control}
                    // />
                    // {errors.location && (
                    //     <Error>{errors.location.message}</Error>
                    // )}
                </InputWrapper>   
      null} */}

      {/* {watchFacility === "regional" ? (
        <div>
          <InputWrapper>
            <Label htmlFor="location">Regional System Name</Label>
            <Input
              id="location"
              type="text"
              {...(register &&
                register("location", {
                  required: "Facility name is required.",
                }))}
            />
            {errors.location && <Error>{errors.location.message}</Error>}
          </InputWrapper>

          <p>Apply to:</p>
          <ul>
            <li>Initiate group participation enrollment process</li>
            <li>Includes group-specific URL / map</li>
            <li>Save 10% / facility, or more</li>
            <li>Minimum 5 facilities</li>
          </ul>
        </div>
      ) : null} */}
    </>
  );
};

export default FormFacility;
