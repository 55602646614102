import React, { useState, useRef } from "react";
import styled from "styled-components";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch";
import { useInstantSearch, useSearchBox } from "react-instantsearch";

import capitalize from "./helpers/capitalize";
import Loading from "./Loading";
import { Controller } from "react-hook-form";

const searchClient = algoliasearch(
  "9PKBQRNS3S",
  "37e874914a0f2292fbd52d7f6e1573b3"
);

const Hit = ({ hit, setValue, setInputValue, setHitsOpen, setHospitalId }) => {
  const capitalizedAddress = `${capitalize(hit.address)}, ${capitalize(
    hit.city
  )}, ${hit.State}, ${hit.zip}`;
  return (
    <Suggestion
      onClick={() => {
        setValue("emergency_location", hit.location_id);
        setHitsOpen(false);
        setInputValue(`${capitalize(hit.title)}, ${capitalizedAddress}`);
        setHospitalId(hit.location_id);
      }}
    >
      <span className="title">{capitalize(hit.title)}</span>
      <span className="place">{capitalizedAddress}</span>
    </Suggestion>
  );
};

function CustomSearchBox(props) {
  const { query, refine } = useSearchBox(props);
  const { status } = useInstantSearch();
  const [inputValue, setInputValue] = useState(query);
  const [hitsOpen, setHitsOpen] = useState(query);
  const inputRef = useRef(null);

  const isSearchStalled = status === "stalled";

  function setQuery(newQuery) {
    setInputValue(newQuery);

    refine(newQuery);
  }

  return (
    <SearchWrapper>
      <Controller
        control={props.control}
        name="emergency_location"
        rules={{ required: "Facility is required." }}
        render={({ field }) => {
          // sending integer instead of string.
          return (
            <input
              {...field}
              ref={inputRef}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              placeholder="Search for a name or address"
              spellCheck={false}
              maxLength={512}
              type="search"
              value={inputValue}
              onChange={(event) => {
                // props.setHospitalId(null);
                setHitsOpen(true);
                setQuery(event.currentTarget.value);
                field.onChange(event.currentTarget.value);
                props.setValue("emergency_location", "");
              }}
              onBlur={() => {
                setTimeout(() => {
                  setHitsOpen(false);
                }, 300);
              }}
              autoFocus
            />
          );
        }}
      />
      {/* <button
        onClick={() => {
          setInputValue("");
          props.setHospitalId(null);
        }}
        hidden={inputValue.length === 0 || isSearchStalled}
      >
        Reset
      </button> */}
      {isSearchStalled ? <Loading search /> : null}
      {hitsOpen ? (
        <HitsWrapper
          hitComponent={({ hit }) => (
            <Hit
              hit={hit}
              setValue={props.setValue}
              setInputValue={setInputValue}
              setHospitalId={props.setHospitalId}
              setHitsOpen={setHitsOpen}
            />
          )}
        />
      ) : null}
    </SearchWrapper>
  );
}

const EmergencySearch = ({ control, setValue, setHospitalId }) => {
  return (
    <InstantSearch searchClient={searchClient} indexName="locations">
      {/* <Autocomplete control={control} setHospitalId={setHospitalId} /> */}
      <CustomSearchBox
        // submitIconComponent={() => null}
        // onSubmit={(e) => e.preventDefault()}
        control={control}
        setValue={setValue}
        setHospitalId={setHospitalId}
      />
    </InstantSearch>
  );
};

export default EmergencySearch;

const HitsWrapper = styled(Hits)`
  position: absolute;
  background-color: #fff;

  ol {
    list-style: none;
    padding: 0;
  }
`;

const Suggestion = styled.p`
  cursor: pointer;
  color: #1b4584;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-align: left !important;
  max-width: 100%;
  padding: 7px 20px;
  margin: 0 !important;

  .title {
    display: block;
    font-weight: 700 !important;
  }

  .place {
    font-size: 14px;
    display: block;
  }

  &:hover {
    background-color: #f9f9f9;
  }
`;

const SearchWrapper = styled.div`
  position: relative;

  input {
    display: block;
    width: 100%;
    background: transparent;
    border: 0;
    height: 40px;
    font-size: 17px;
    font-weight: 400;
  }
`;
