import React, { useState, useContext, useEffect } from "react";
import Loading from "../Loading";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import styled from "styled-components";
import firebaseApp, { db } from "../../firebase";
import { useForm } from "react-hook-form";
import PlacesAutocomplete from "../PlacesAutocomplete";
import { useStripe } from "@stripe/react-stripe-js";
import ModalGeneric from "../ModalGeneric";
import ModalSlideUp from "../ModalSlideUp";
import Button from "../styled/Button";
import { CreateNewApiToken } from "../../API";
import Search from "components/Search";
import Pricing from "./Pricing";
import { Navigate } from "react-router-dom";
import { AuthContext } from "components/Auth";

const InputWrapper = styled.div`
  border-top: ${(props) => (props.top ? "0.55px solid #c8c7cc" : "none")};
  padding-top: ${(props) => (props.top ? "10px" : "0")};
  border-bottom: 0.55px solid #c8c7cc;
  margin-bottom: 10px;
`;

const TwoColumn = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
  /* color: #000; */
`;

const Billing = ({ uid, role, subscription }) => {
  const stripe = useStripe();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [location, setLocation] = useState();
  const [loading, setLoading] = useState();
  const [viewingApiDocs, setViewingApiDocs] = useState();
  const [viewingAddressForm, setViweingAddressForm] = useState();
  const { user, userData, userLoading } = useContext(AuthContext);

  const handleLogout = async () => {
    setLoading("logout");
    await firebase.auth().signOut();
    return <Navigate to="/login/" />;
  };

  const sendToPortal = async () => {
    setLoading("portal");

    const functionRef = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      returnUrl: window.location.href,
    });
    window.location.assign(data.url);
  };

  useEffect(() => {
    if (userData?.location) {
      db.collection("locations")
        .doc(userData?.location)
        .get()
        .then((l) => {
          setLocation(l.data());
        });
    }
  }, [userData]);

  const ApiInfoForm = (showConfirm) => {
    const [confirmingTokenChange, setConfirmingTokenChange] = useState(
      showConfirm === true
    );
    return (
      <ModalGeneric width="60%" visible={viewingApiDocs}>
        <h3>API Token (For Developers)</h3>
        <input
          style={{ width: "100%" }}
          readOnly
          value={userData?.apiToken}
        ></input>
        <Button onClick={() => setConfirmingTokenChange(true)}>
          Create New Token
        </Button>
        <br></br>

        <p>
          In order to query our API, you will need to set the Authorization
          header of the request to this API token.
        </p>
        <p>
          {process.env.REACT_APP_ENV === "production"
            ? "URL Production: https://us-central1-hlthdsk.cloudfunctions.net/api"
            : "URL Staging: https://us-central1-caremap2020.cloudfunctions.net/api/"}{" "}
        </p>
        <label>Endpoints: </label>
        <ul>
          <li>
            GET "/api/registrations/" to return a list of all your facilities
            registrations )
            <p>
              Example Return:
              {`{
"registrations": [
{
"email": "support@hlthdsk.com",
"photoId": "registrations/14mHwvkpXUbblONqlA6S.jpg",
"location": "131313",
"submitted": true,
"id": "14mHwvkpXUbblONqlA6S",
"name": "RDC",
"patient": 1
}
]
}`}
            </p>
          </li>
        </ul>
        <p></p>
        <Button
          onClick={() => {
            setViewingApiDocs(false);
          }}
        >
          Okay
        </Button>

        <ModalSlideUp modalVisible={confirmingTokenChange}>
          <h2>Are you sure you want to update your API Token?</h2>
          <Button
            onClick={() => {
              CreateNewApiToken().then((data) => {
                window.location.reload();
              });
              setConfirmingTokenChange(false);
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setConfirmingTokenChange(false)}>No</Button>
        </ModalSlideUp>
      </ModalGeneric>
    );
  };

  const AddressForm = () => {
    const updateLocation = (latlng, address) => {
      location.address = address;
      location.lat = latlng.lat;
      location.lng = latlng.lng;
      console.log("save me", location, location.id);
      db.collection("locations")
        .doc(location.id)
        .update({ address: address, lat: latlng.lat, lng: latlng.lng })
        .then((l) => {
          setLocation(l);
          window.location.reload();
        });
    };
    return (
      <ModalGeneric width="80%" visible={true}>
        <label>Address:</label>
        <p>{location?.address}</p>

        <Search updateLocation={updateLocation} addressField />
        <Button onClick={() => setViweingAddressForm(false)}>Close</Button>
      </ModalGeneric>
    );
  };

  return (
    <div className="inner">
      <h1>HealthDesk Account Management</h1>

      {subscription ? (
        <>
          {subscription === "facility" ? (
            <>
              <Button
                type="submit"
                onClick={() => {
                  setViewingApiDocs(true);
                }}
                disabled={loading}
              >
                View API Info
              </Button>
              <hr></hr>
              <Button
                type="submit"
                onClick={() => {
                  setViweingAddressForm(true);
                }}
                disabled={loading}
              >
                Manage Facility Address
              </Button>
              <hr></hr>
            </>
          ) : null}

          <Button type="submit" onClick={sendToPortal} disabled={loading}>
            {loading === "portal" ? <Loading /> : "Manage Subscription"}
          </Button>
          <Button onClick={handleLogout} disabled={loading === "logout"}>
            {loading === "logout" ? <Loading /> : "Log Out"}
          </Button>

          {viewingApiDocs ? <ApiInfoForm /> : null}
          {viewingAddressForm ? <AddressForm location={location} /> : null}
        </>
      ) : (
        <Pricing uid={uid} role={role} subscription={subscription} />
      )}
    </div>
  );
};

export default Billing;
