import React, { useState, useContext } from "react";
import Loading from "./Loading";
import { AuthContext } from "./Auth";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import firebaseApp from "../firebase";
import { useForm } from "react-hook-form";

import PageWrapper from "./styled/PageWrapper";
import Input from "./styled/Input";
import Button from "./styled/Button";

const InputWrapper = styled.div`
  border-top: ${(props) => (props.top ? "0.55px solid #c8c7cc" : "none")};
  padding-top: ${(props) => (props.top ? "10px" : "0")};
  border-bottom: 0.55px solid #c8c7cc;
  margin-bottom: 10px;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
  color: #000;
`;

const Error = styled.span`
  display: block;
  color: crimson;
  padding-bottom: 5px;
  font-size: 14px;
`;

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [firebaseErrors, setFirebaseErrors] = useState("");

  const onSubmit = ({ email }) => {
    setLoading(true);
    setFirebaseErrors("");

    // Create user and create database entry for user/booklist
    firebaseApp
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setLoading(false);
        setSent(true);
      })
      .catch((error) => {
        setLoading(false);
        setFirebaseErrors(error.message);
      });
  };

  const { user } = useContext(AuthContext);

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  return user !== null ? (
    <Loading />
  ) : (
    <PageWrapper>
      <div>
        <h1>Reset Password</h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              {...register("email", {
                required: "Email is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address.",
                },
              })}
            />
            {errors.email && <Error>{errors.email.message}</Error>}
          </InputWrapper>

          {firebaseErrors && <Error>{firebaseErrors}</Error>}
          <Button type="submit" disabled={loading}>
            {loading ? <Loading /> : "Reset Password"}
          </Button>
        </form>

        {sent && <Label $large>A password reset email has been sent.</Label>}

        <Link to="/login">
          <Button>Go back to login</Button>
        </Link>
      </div>
    </PageWrapper>
  );
};

export default ResetPassword;
