import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Search from "components/Search";
import Filters from "components/Filters";
import Columns from "components/styled/Columns";
import Button from "components/styled/Button";
import Logo from "components/styled/Logo";
import nm from "images/nm.jpeg";
import wray from "images/wray.jpg";
import ShareButtons from "components/styled/ShareButtons";
import MainMenu from "components/MainMenu";
import ColorLegend from "components/ColorLegend";

import { FaMinus, FaPlus, FaSearch } from "react-icons/fa";

import mapMarkerLegend from "images/markers/legend-map-marker.png";
import vaccineMarkerLegend from "images/markers/legend-vaccine-marker.png";
import redMarkerLegend from "images/markers/legend-red-marker.png";
import { useMap } from "react-leaflet";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Popup = styled.p`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-weight: 400 !important;

  a {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const LegendWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 360px;
  margin: 0 auto 0.5rem;

  div {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 0.25rem;
    max-width: 22px;
  }
`;

const VaccineLegend = () => {
  return (
    <LegendWrapper>
      <div>
        <img src={vaccineMarkerLegend} />
        Available Now
      </div>
      <div>
        <img src={redMarkerLegend} />
        Wait List/Virtual Queue Activation Pending
      </div>
    </LegendWrapper>
  );
};

const WaitRoomTimeWrapper = styled.div`
  /* position: absolute; */
  top: 100%;
  background-color: ${(props) => props.theme.colors.white};
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 720px;
  padding: 0.125em 0.275em;
`;

const SearchButtonWrapper = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  right: 5px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 0.5rem 0.6em 0.325rem;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 18px 0 rgba(0, 0, 0, 0.19);
`;

const ZoomWrapper = styled.div`
  position: absolute;
  top: calc(100% + 48px);
  right: 5px;
  background-color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 18px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;

  div:first-child {
    padding: 0.55rem 0.6em 0.225rem;
  }

  div:last-child {
    padding: 0.325rem 0.6em 0.3rem;
  }
`;

const SearchButton = ({ onClick }) => (
  <SearchButtonWrapper onClick={onClick}>
    <FaSearch size={18} color="#1B4584" />
  </SearchButtonWrapper>
);

const ZoomButton = () => {
  const map = useMap();

  return (
    <ZoomWrapper>
      <div onClick={() => map.zoomIn()}>
        <FaPlus size={12} color="#1B4584" />
      </div>
      <div onClick={() => map.zoomOut()}>
        <FaMinus size={12} color="#1B4584" />
      </div>
    </ZoomWrapper>
  );
};

const HeaderWrapper = styled.div`
  position: absolute;
  height: auto;
  padding: 5px;
  font-size: 20px;
  left: 0;
  right: 0;
  top: 0;
  background: #fff;
  font-size: 12px;
  z-index: 9999;
  p {
    text-align: center;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
    z-index: 99;
  }
  .center {
    text-align: center;
  }
  p {
    text-align: center;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
  }
  h2 {
    text-align: center;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
    margin-top: 2px;
    margin-bottom: 6px;
    font-size: 16px;
  }
`;

export default ({
  data,
  filter,
  setFilter,
  setCoords,
  updateMap,
  searchLoaded,
  currentRegion,
  setRegional,
  vaccine,
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <HeaderWrapper>
      <Columns $main>
        <LogoWrapper>
          <MainMenu
            toggleMenu={() => setShowMenu(!showMenu)}
            visible={showMenu}
          />
          <Logo />
        </LogoWrapper>
        <ShareButtons />
        <Link to="/dashboard">
          <Button $small $noMargin>
            {currentRegion === "wray" ? "Dashboard" : "Subscribe/Log In"}
          </Button>
        </Link>
      </Columns>

      {/* These are temporary logos hardcoded for regional demos--when regional feature is built out these will be removed */}
      {currentRegion === "nm" && (
        <div className="center">
          <img src={nm} width="175px" />
        </div>
      )}
      {currentRegion === "wray" && (
        <div className="center">
          <img src={wray} width="175px" />
        </div>
      )}
      {currentRegion === "nm" && (
        <div className="center">
          <label>
            <input
              type="checkbox"
              onChange={(e) => setRegional(e.target.checked)}
            />
            Include other medical groups
          </label>
        </div>
      )}

      <h2>
        {vaccine
          ? "Same Day Vaccine Availability"
          : "Same Day Healthcare Availability"}
      </h2>
      {vaccine ? <VaccineLegend /> : null}
      {currentRegion !== "wray" && (
        <Filters
          data={data}
          filter={filter}
          setFilter={setFilter}
          vaccine={vaccine}
        />
      )}

      <Popup style={{ lineHeight: 1.2, paddingTop: "0.25rem" }}>
        Tip: Facilities with a Virtual Queue (Q) can reduce your time in the
        waiting room dramatically. Tap on the marker to Save Your Spot in the
        Queue.
      </Popup>

      {filter !== "healthcard" && filter !== "videocare" && (
        <WaitRoomTimeWrapper>
          {filter === "queue" &&
          !data.filter((location) => location?.queueEnabled).length ? (
            <Popup>
              Sorry...no facilities in your area have created a Virtual Queue.
              Perhaps you should suggest this to them?
            </Popup>
          ) : (
            <>
              <Popup>
                Anticipated Waiting Room Experience (Best Data Available)
              </Popup>
              <ColorLegend />
            </>
          )}
        </WaitRoomTimeWrapper>
      )}

      <SearchButton onClick={() => setShowSearch(!showSearch)} />
      <ZoomButton onClick={() => setShowSearch(!showSearch)} />
      <Search
        updateMap={updateMap}
        searchLoaded={searchLoaded}
        open={showSearch}
        setCoords={setCoords}
      />
    </HeaderWrapper>
  );
};
