import React, { useState, useContext } from "react";
import styled from "styled-components";
import firebase from "firebase/compat/app";
import { db } from "../firebase";
import { useForm } from "react-hook-form";
import { AuthContext } from "components/Auth";

import SeatRating from "./SeatRating";
import Loading from "./Loading";
import SeatData from "./SeatData";
import QueueForm from "./queue/QueueForm";

import Button from "./styled/Button";
import Text from "./styled/Text";
import TextLink from "./styled/TextLink";
import Columns from "./styled/Columns";
import ModalWrapper from "./styled/ModalWrapper";
import { FaStar } from "react-icons/fa6";

const CloseModal = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  font-size: 30px;
  line-height: 20px;
`;

const InputWrapper = styled.div`
  border-top: ${(props) => (props.top ? "0.55px solid #c8c7cc" : "none")};
  padding-top: ${(props) => (props.top ? "10px" : "0")};
  border-bottom: 0.55px solid #c8c7cc;
  margin-bottom: 10px;
`;

const VideoLink = styled.button`
  width: 100%;
  border-radius: 4px;
  height: 2.8em;
  font-size: 16px;
  border: 0;
  color: #fff;
  background-color: ${(props) => props.theme.colors.primary};
  margin: 0 0 0.4rem;
  padding: 0 1em;
  font-size: 16px;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
  color: ${({ $success }) =>
    $success ? "hsl(90.3225806451613,75%,50%)" : "#000"};
  margin-top: ${({ $large }) => ($large ? "10px" : "0")};
`;

const VideoLabel = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
  color: ${(props) => props.theme.colors.primary};
  margin-top: -20px;
  padding: 15px 0;
  text-align: center;
`;

const Error = styled.span`
  display: block;
  color: crimson;
  padding-bottom: 5px;
  font-size: 14px;
`;

const Modal = ({
  modalOpen,
  setModalOpen,
  modalVisible,
  setModalVisible,
  userLocation,
  error,
}) => {
  const { user } = useContext(AuthContext);
  const { handleSubmit, register, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [dbError, setDbError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [emailSent, setEmailSent] = useState();
  const [messageSent, setMessageSent] = useState();
  const hasTime = modalOpen.averageWaitTime || modalOpen.waitScore;

  const capitalize = (string) => {
    var splitString = string.toLowerCase().split(" ");
    for (var i = 0; i < splitString.length; i++) {
      splitString[i] =
        splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
    }
    return splitString.join(" ");
  };

  const locationName = modalOpen?.title;
  const locationRef = modalOpen?.id?.toString();

  const onSubmit = ({ time }) => {
    setLoading(true);

    db.collection("locations")
      .doc(locationRef)
      .update({
        waitTimes: firebase.firestore.FieldValue.arrayUnion({
          date: Date.now(),
          waitTime: time,
        }),
      })
      .then(function () {
        setLoading(false);
        setSubmitted(true);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        setDbError(true);
      });
  };

  const withinRadius = (point, interest, kms) => {
    if (userLocation !== undefined) {
      let R = 6371;
      let deg2rad = (n) => {
        return n * (Math.PI / 180);
      };

      let dLat = deg2rad(interest.latitude - point.latitude);
      let dLon = deg2rad(interest.longitude - point.longitude);

      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(point.latitude)) *
          Math.cos(deg2rad(interest.latitude)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      let c = 2 * Math.asin(Math.sqrt(a));
      let d = R * c;

      return d <= kms;
    }
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = String(date.getFullYear()).substring(2);
    const hours = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `${month}/${day}/${year} ${hours}`;
  };

  const sendMessage = (number) => {
    setMessageSent("loading");
    db.collection("messages")
      .add({
        to: number,
        body: `A user requested to know the current state of the waiting room at your facility. You can update your facility's estimate by signing in at hlthdsk.com.`,
      })
      .then(() => setMessageSent("sent"));
  };

  const getFacilityTypeName = () => {
    let facilityType = "";
    {
      if (modalOpen.type === "Clinic") {
        facilityType = "Clinic / Immediate Care";
      } else if (modalOpen.type === "Emergency Department") {
        facilityType = "Emergency Department";
      }
      return facilityType;
    }
  };

  return (
    <ModalWrapper $visible={modalVisible} welcome={modalOpen.welcome}>
      <div>
        <CloseModal onClick={() => setModalVisible(false)}>&times;</CloseModal>
        <h3>{modalOpen.title && capitalize(modalOpen.title)}</h3>
        {modalOpen.star ? <StarRating rating={modalOpen?.star} /> : null}
        <span className="type">{getFacilityTypeName()}</span>
        <span className="time">
          {modalOpen.waitScore && !modalOpen.averageWaitTime
            ? "Anticipated Waiting Room Experience (Best Data Available)"
            : "Waiting Time Estimate"}
        </span>
        {modalOpen.waitScore && !modalOpen.averageWaitTime && (
          <SeatData waitTime={modalOpen.waitScore} />
        )}
        {modalOpen.averageWaitTime && (
          <SeatData waitTime={modalOpen.averageWaitTime} />
        )}
        {!hasTime && (
          <VideoLabel>No current estimated waiting room time.</VideoLabel>
        )}

        <VideoLabel>
          {modalOpen.lastUpdated
            ? `Last Updated: ${formatTime(Date(modalOpen.lastUpdated))}`
            : hasTime && (
                <>
                  <span>Last Updated: 03/08/2024*</span>
                  {user ? null : (
                    <TextLink $center to="/register">
                      Claim this Facility
                    </TextLink>
                  )}
                </>
              )}
        </VideoLabel>

        {modalOpen?.telehealth && (
          <>
            <a
              href={`https://app.interlockhealth.dachs.com/?directshare=${modalOpen.telehealth}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <VideoLink>Get Pre(ER) Now</VideoLink>
            </a>
            <VideoLabel>
              Connect to Pre(ER) Now for simple healthcare needs and/or same-day
              healthcare advice and prescriptions except controlled substances
            </VideoLabel>
            <a target="_blank" href="https://interlockhealth.com/hdlm">
              <Button $small>Learn More</Button>
            </a>
          </>
        )}

        {modalOpen.lab && (
          <div>
            <Label>On Site Capabilities</Label>
          </div>
        )}

        {modalOpen.lab && (
          <Columns>
            <div>
              <Label>Lab</Label>
            </div>
            <div>{modalOpen.lab === "yes" ? "Y" : "N"}</div>
          </Columns>
        )}

        {modalOpen.xray && (
          <Columns>
            <div>
              <Label>Xray (plain films)</Label>
            </div>
            <div>{modalOpen.xray === "yes" ? "Y" : "N"}</div>
          </Columns>
        )}

        {modalOpen.ultrasound && (
          <Columns>
            <div>
              <Label>Ultrasound</Label>
            </div>
            <div>{modalOpen.ultrasound === "yes" ? "Y" : "N"}</div>
          </Columns>
        )}

        {modalOpen.ct && (
          <Columns>
            <div>
              <Label>CT</Label>
            </div>
            <div>{modalOpen.ct === "yes" ? "Y" : "N"}</div>
          </Columns>
        )}

        {modalOpen.mri && (
          <Columns>
            <div>
              <Label>MRI</Label>
            </div>
            <div>{modalOpen.mri === "yes" ? "Y" : "N"}</div>
          </Columns>
        )}

        {/* {modalOpen.beds && (
                            <Columns>
                                <div>
                                    <Label>Reported Bed Availability</Label>
                                </div>
                                <div>
                                    <Columns>
                                        <div>General</div>
                                        <div>
                                            {modalOpen.beds == 0 ? "N" : "Y"}
                                        </div>
                                    </Columns>
                                    <Columns>
                                        <div>ICU</div>
                                        <div>
                                            {modalOpen.icu == 0 ? "N" : "Y"}
                                        </div>
                                    </Columns>
                                </div>
                            </Columns>
                        )} */}
        <hr></hr>
        <QueueForm
          queueEnabled={modalOpen.queueEnabled}
          queueNumber={modalOpen.queueNumber}
          queueCap={modalOpen.queueCap}
          queueLength={modalOpen.queueLength}
          locationName={locationName}
          locationRef={locationRef}
          firebase={firebase}
          db={db}
          textSequence={modalOpen.textSequence}
        />

        {modalOpen.location &&
          !error &&
          withinRadius(userLocation, modalOpen.location, 0.5) &&
          (dbError ? (
            <Error>Error submitting the form. Please try again later.</Error>
          ) : submitted ? (
            <Text $center>Thank you. Your estimate has been submitted.</Text>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputWrapper>
                <Label>
                  If you are in the waiting room, how full is the waiting room
                  now?
                </Label>
                <SeatRating register={register} />
                {errors.time && <Error>{errors.time.message}</Error>}
              </InputWrapper>
              <Button disabled={loading} type="submit">
                {loading ? <Loading /> : "Submit"}
              </Button>
            </form>
          ))}

        {modalOpen?.email &&
          !modalOpen?.queueEnabled &&
          !modalOpen?.admin &&
          !modalOpen?.phone && (
            <>
              {emailSent === "sent" ? (
                <Label $large $success>
                  <strong>Thank you. An email has been sent.</strong>
                </Label>
              ) : (
                <a
                  href={`mailto:${
                    modalOpen.email
                  }?subject=HealthDesk Waiting Time Request&body=${encodeURIComponent(
                    `Hello,\nToday more than ever, patients like me need help deciding where to seek medical care.  I found your institution listed at the HealthDesk, and realized that ${capitalize(
                      modalOpen.title
                    )} isn’t an active participant, yet.\n\nThe HealthDesk (https://healthdesk.interlockhealth.com) helps patients match medical need to healthcare capability. It is, effectively, a digital front door for potential patients.\n\nPatients who need to find healthcare use the HealthDesk to find:\n\n- Urgent Care / Immediate Care centers\n- Emergency Departments\n- Pre(ER) – a telehealth service that connects patients to Emergency Department healthcare personnel.  Incidentally, your ED should be offering this service. (https://interlockhealth.com/pelm)\n- Institutions that welcome the HealthCard (https://interlockhealth.com/hclm/)\n\nYou should probably attend a webinar to learn more, (https://interlockhealth.com/hdlm/) then ask someone at your institution to create a HealthDesk subscription for ${capitalize(
                      modalOpen.title
                    )}?\n\nIt’s hard to change anything during a pandemic - these are difficult days. Still, you’ll probably have an immediate return on investment, as the revenue from just one patient who is led to seek care at ${capitalize(
                      modalOpen.title
                    )} will exceed your investment in the annual HealthDesk registration fee.\n\nOnce more, your next step is probably to attend a webinar to learn more, where you’ll get to talk to an Interlock Health leader in person.  Go to https://interlockhealth.com/hdlm/ to sign up.\n\nBest regards.`
                  )}`}
                >
                  <Button $small>Request Current WR Update</Button>
                </a>
              )}
            </>
          )}

        {modalOpen?.phone && !modalOpen?.queueEnabled && (
          <>
            {emailSent === "sent" ? (
              <Label $large $success>
                <strong>Thank you. A message has been sent.</strong>
              </Label>
            ) : (
              <Button
                onClick={() => {
                  setEmailSent("sent");
                  sendMessage(modalOpen.phone);
                }}
                $small
              >
                {messageSent === "loading" ? (
                  <Loading />
                ) : (
                  "Request Current WR Update"
                )}
              </Button>
            )}
          </>
        )}
      </div>
    </ModalWrapper>
  );
};

const StarWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 0.75rem;
  margin-top: -0.25rem;
`;

const StarRating = ({ rating }) => {
  let stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(
      <FaStar color={i < rating ? "#FFC404" : "#E1E4EB"} size="1rem" key={i} />
    );
  }

  return <StarWrapper>{stars}</StarWrapper>;
};

export default Modal;
