import { useState, useEffect } from "react";

export default () => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          setPosition({
            latitude: coords.latitude,
            longitude: coords.longitude,
          });
        },
        (error) => {
          // Fallback coordinates, to Naperville as default for now, in the future probably based on IP
          setPosition({
            latitude: 40.703545,
            longitude: -89.579086,
          });
          setError(error.message);
        }
      );
    } else {
      // Fallback coords
      setPosition({
        latitude: 41.789234,
        longitude: -88.151894,
      });
      setError(error.message);
    }
  }, []);

  return { ...position, error };
};
