import React, { useState } from "react";
import Loading from "../Loading";
import { db } from "../../firebase";
import { useForm } from "react-hook-form";
import ModalGeneric from "../ModalGeneric";
import Button from "../styled/Button";
import styled from "styled-components";
import Input from "components/styled/Input";
import CloseButton from "components/styled/CloseButton";

const InputWrapper = styled.div`
  border-top: ${(props) => (props.top ? "0.55px solid #c8c7cc" : "none")};
  padding-top: ${(props) => (props.top ? "10px" : "0")};
  border-bottom: 0.55px solid #c8c7cc;
  margin-bottom: 10px;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
  color: #1b4584;
`;

const Error = styled.span`
  display: block;
  color: crimson;
  padding-bottom: 5px;
  font-size: 14px;
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  font-size: 17px;
  font-weight: 400;
  padding: 0;
  background-color: transparent;
  border: none;
`;

const UserProfileSettings = ({ user, data, visible, close }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState();
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = ({ sex, age }) => {
    setLoading(true);
    setSubmitted(false);
    db.collection("users")
      .doc(user.uid)
      .update({
        profile: { sex, age },
      })
      .then(function () {
        setLoading(false);
        setSubmitted(true);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      <ModalGeneric visible={visible} close={close}>
        <CloseButton onClick={close} />
        <h3 style={{ marginTop: "0", marginBottom: "1.5rem" }}>
          Update your profile
        </h3>
        {data ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ paddingBottom: "0.5rem" }}
          >
            <InputWrapper>
              <Label htmlFor="sex">Sex</Label>
              <Select
                id="sex"
                {...register("sex", {
                  required: "Sex is required.",
                })}
                defaultValue={data?.profile?.sex || ""}
              >
                <option disabled></option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Select>
              {errors.sex && <Error>{errors.sex.message}</Error>}
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor="age">Age</Label>
              <Input
                id="age"
                type="number"
                {...register("age", {
                  required: "Age is required.",
                })}
                defaultValue={data?.profile?.age}
              />
              {errors.age && <Error>{errors.age.message}</Error>}
            </InputWrapper>

            <Button type="submit" disabled={loading}>
              {loading ? <Loading /> : "Update Profile"}
            </Button>
          </form>
        ) : null}

        {submitted && (
          <Label $large style={{ paddingBottom: "1rem" }}>
            Your profile has been updated.
          </Label>
        )}
      </ModalGeneric>
    </>
  );
};

export default UserProfileSettings;
