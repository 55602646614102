import styled from "styled-components";

const ModalWrapper = styled.div`
  position: fixed;
  height: auto;
  padding: 10px;
  font-size: 20px;
  left: 0;
  right: 0;
  bottom: ${({ $visible }) => ($visible ? "0" : "-100vh")};
  margin: 0 auto;
  width: 100vw;
  max-width: 960px;
  box-sizing: border-box;
  background: #fff;
  transition: 400ms ease-in-out bottom;
  z-index: 9999;
  h3 {
    text-align: center;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5rem;
    max-width: 80%;
  }

  p {
    font-size: 14px;
  }

  .type {
    display: block;
    padding-bottom: 1rem;
    text-align: center;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
    font-size: 0.825rem;
  }

  .time {
    display: block;
    padding-bottom: 0.5rem;
    text-align: center;
    color: ${(props) => props.theme.colors.primary};
    font-size: 0.75rem;
  }
`;

export default ModalWrapper;
