import { useEffect, useState } from "react";
import useDebounce from "hooks/useDebounce";

const useSearch = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const query = useDebounce(value, 1000);

  useEffect(() => {
    if (query && loading) getSuggestions();

    async function getSuggestions() {
      try {
        const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${process.env.REACT_APP_MAPBOX_API}&country=US&autocomplete=true`;
        const response = await fetch(endpoint);
        const results = await response.json();
        setSuggestions(results?.features);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data, ", error);
      }
    }
  }, [query]);

  const handleChange = (event) => {
    setLoading(true);
    setValue(event.target.value);
  };

  return {
    value,
    onChange: handleChange,
    setValue,
    loading,
    suggestions,
    setSuggestions,
  };
};

export default useSearch;
