import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "./styled/Button";
import logo from "../logo-text.png";
import { useForm } from "react-hook-form";
import InputField from "./styled/InputField";
import Text from "./styled/Text";
import firebaseApp, { db } from "../firebase";
import Error from "./styled/Error";
const messaging = require("util/messaging");
//import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
const CloseModal = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  font-size: 30px;
  line-height: 20px;
`;
const Background = styled.div`
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 200ms ease-in-out;
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
`;

const WelcomeWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 90%;
  max-width: 400px;
  z-index: 99999;
  padding: 10px 10px 5px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  display: ${(props) => (props.visible ? "block" : "none")};
  visibility: ${(props) => (props.visible ? "visible" : "invisible")};
  transition: opacity 200ms ease-in-out;

  img {
    max-width: 175px;
    margin-bottom: -10px;
  }

  h1 {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 0;
  }

  .warning {
    color: rgb(223, 32, 32);
  }
`;

const CardWrapper = styled.div`
  width: 125mm;
  height: 88mm;
  position: relative;
  zoom: 75%;
`;
const Card = styled.div`
  border-radius: 10px;
  border: 5px solid #b4b6b9 !important;
  width: 100%;
  height: 100%;
  background-color: #2577ae;
`;
const CardLabel = styled.h2`
  color: #fff;
  float: left;
  margin: 2px;
`;
export default () => {
  const [visible, setVisible] = useState(true);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [showCard, setShowCard] = useState(false);
  const toPng = () => {};
  const onSubmit = async ({ email, phone }) => {
    let message = "";

    messaging.sendHealthCard(db, email, phone);

    setVisible(false);
    //   const file1 = await toPng(document.getElementById('my-node'))
    //   .then(async (dataUrl) => {
    //
    //     await storageRef
    //       .child(filePath1)
    //       .put(dataUrl)
    //       .then(async(snapshot) => {
    //           snapshot.ref.getDownloadURL().then((downloadUrl) => {
    //             imageUrls.push(downloadUrl);
    //       })
    //
    //   });
    // });
    //
    //
    //   const file2 = await toPng(document.getElementById('my-node'))
    //   .then(async (dataUrl) => {

    //   await storageRef
    //     .child(filePath2)
    //     .put(dataUrl)
    //     .then((snapshot) => {
    //         snapshot.ref.getDownloadURL().then((downloadUrl) => {
    //           imageUrls.push(downloadUrl);
    //     })
    //
    // });
    // });
    //   storageRef
    //     .child(filePath1)
    //     .put(file1)
    //     .then((snapshot) => {
    //         snapshot.ref.getDownloadURL().then((downloadUrl) => {
    //           imageUrls.push(downloadUrl);
    //         }).then(()=>{
    //           storageRef
    //           .child(filePath2)
    //           .put(file2)
    //           .then((snapshot) => {
    //             snapshot.ref.getDownloadURL().then((downloadUrl) => {
    //               imageUrls.push(downloadUrl);
    //
    //
    //
    //             })
    //           });
    //         })
    //     });
    //
  };

  useEffect(() => {
    setShowCard(false);
  });

  return (
    <>
      <Background visible={visible} />
      <WelcomeWrapper visible={visible}>
        <CloseModal onClick={() => setVisible(false)}>&times;</CloseModal>
        <img src={logo} alt="HealthDesk" />
        {showCard ? (
          <>
            <br></br>
            <CardWrapper>
              <Card>
                <h1 style={{ color: "white", "font-size": "35px" }}>
                  HEALTHCARD BASIC
                </h1>
                <CardLabel>MEMBER: 864394</CardLabel>
                <br></br>
                <br></br>
                <CardLabel>BIN: 018661</CardLabel>
                <br></br>
                <br></br>
                <CardLabel>GROUP: EDC4248</CardLabel>
                <br></br>
                <br></br>
                <CardLabel>PCN: EDC</CardLabel>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </Card>
            </CardWrapper>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2>
              SIGNIFICANT MEDICATION DISCOUNTS AVAILABLE WITH HEALTHCARD BASIC
            </h2>
            <InputField
              type="tel"
              name="phone"
              label="Phone"
              register={register}
              rules={{
                required: "Phone number is required.",
                pattern: {
                  value: /^[1-9]\d{2}\s\d{3}\s\d{4}/,
                  message: "Invalid phone number.",
                },
              }}
              placeholder="123 456 7890"
              autoComplete="tel"
            />
            {errors.phone && <Error>{errors.phone.message}</Error>}
            <InputField
              name="email"
              type="email"
              label="Email Address"
              register={register}
              rules={{
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address.",
                },
              }}
            />
            {errors.email && <Error>{errors.email.message}</Error>}
            <br></br>
            <Button $small type="submit">
              GET HEALTHCARD BASIC
            </Button>

            <Text>
              By providing phone number, you agree to receive electronic
              communication from the HealthDesk (rates and charges may apply)
            </Text>
          </form>
        )}
      </WelcomeWrapper>
    </>
  );
};
