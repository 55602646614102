import React, { useState } from "react";

import Loading from "../Loading";
import InputField from "../styled/InputField";
import Text from "../styled/Text";
import Switch from "../styled/Switch";

import QueuePatient from "./QueuePatient";
import ControlledInputField from "components/styled/ControlledInputField";

const WaitingRoom = ({
  data,
  setData,
  queue,
  defaultValue,
  toggleWaitingRoom,
  textPatient,
  deletePatient,
  waitingRoomLoading,
  setWaitingRoomCap,
  cap,
}) => {
  return (
    <div className="inner">
      <h1>Healthcare Waiting Room</h1>
      <Switch
        label="Use Virtual Waiting Room?"
        onChange={toggleWaitingRoom}
        defaultValue={defaultValue}
      />
      {waitingRoomLoading ? <Loading /> : null}

      {defaultValue ? (
        <>
          <ControlledInputField
            label="Maximum Queue"
            name="cap"
            type="number"
            onChange={setWaitingRoomCap}
            defaultValue={cap}
          />
          {queue?.length ? (
            <div>
              {queue.map((patient) => (
                <QueuePatient
                  key={patient.date}
                  data={data}
                  setData={setData}
                  patient={patient}
                  textPatient={textPatient}
                  deletePatient={deletePatient}
                />
              ))}
            </div>
          ) : (
            <Text>No patients in queue.</Text>
          )}
        </>
      ) : null}
    </div>
  );
};

export default WaitingRoom;
