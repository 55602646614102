import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Button from "./styled/Button";

import capitalize from "./helpers/capitalize";

import logo from "../logo-icon.png";

const Wrapper = styled.div`
  box-sizing: border-box;
  width: calc(90% - 50px);
  height: auto;
  max-width: 860px;
  font-size: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  margin: 0 auto;
  position: fixed;

  span {
    font-size: 12px;
    color: #000;
  }

  button {
    border-radius: 4px;
  }

  .slick-track {
    display: flex;
    align-items: flex-end;
  }

  .slick-slide {
    padding: 10px;
  }

  .slick-prev {
    top: initial;
    bottom: 12px;
    left: -30px;
  }

  .slick-next {
    top: initial;
    bottom: 12px;
    right: -30px;
  }

  @media (max-width: 1024px) {
    font-size: 16px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto 0 0;
    position: fixed;

    .slick-prev {
      top: -10px;
      left: 10px;
    }

    .slick-next {
      top: -10px;
      left: 40px;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    color: ${(props) => props.theme.colors.primary};
  }

  .attribution {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;

    img {
      padding-right: 5px;
    }

    * {
      flex: 1;
    }
  }
`;

const Slide = styled.div`
  background-color: #fff;
  padding: 5px;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;

  h2 {
    font-size: 16px;
    margin: 0;
    margin-top: 5px;
  }
`;
const P = styled.div`
  margin-left: 5px;
  font-size: 16px;
  color: #1b4584;
  white-space: pre-line;
  text-align: left;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;
const FloatRight = styled.div`
  display: flex;
  align-items: center;
  float: right;
  margin-left: 10px;

  & > * {
    float: right;
  }
  @media (max-width: 1024px) {
    margin-left: 0;
    margin-top: 2px;
    flex-basis: 100% !important;

    button {
      font-size: 13px !important;
    }
  }
`;
const Anchor = styled.a`
  margin-left: 1px;
  padding: 6px 0;
  color: #1b4584;
  text-align: center;
  font-size: 14px;
  @media (max-width: 480px) {
  }
`;

const AdSlide = ({ message, buttonClick, buttonText }) => {
  return (
    <Slide className="attribution" onClick={() => buttonClick()}>
      <img src={logo} alt="" style={{ maxWidth: "35px" }} />

      <P dangerouslySetInnerHTML={{ __html: message }}></P>
      <FloatRight>
        <Button
          style={{
            marginTop: "3px",
            marginBottom: "2px",
            padding: "7px",
            height: "28px",
          }}
          $small
          $noMargin
        >
          {buttonText}
        </Button>
        {/* <Anchor target="_blank" href={textLink}>
          Learn More
        </Anchor> */}
      </FloatRight>
    </Slide>
  );
};
const AdCarousel = ({ callbacks = null }) => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    dots: false,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  const slides = [
    {
      message: "Medical SuperIntelligence: Take A Stroll",
      button: "Try for Free",
      buttonClick: () => (window.location.href = "/register"),
    },
    {
      message: "Symptom Checker, with SuperIntelligence",
      button: "Try for Free",
      buttonClick: () => (window.location.href = "/register"),
    },
    {
      message:
        "<strong>One Does Not Simply</strong> - Check Out SuperIntelligence",
      button: "Try for Free",
      buttonClick: () => (window.location.href = "/register"),
    },
    {
      message: "<strong>Is It Though? - Enter your symptoms</strong>",
      button: "Try for Free",
      buttonClick: () => (window.location.href = "/register"),
    },
    {
      message: "Discuss Health Care on <strong>Your</strong> Schedule",
      button: "Try for Free",
      buttonClick: () => (window.location.href = "/register"),
    },
  ];

  return (
    <>
      <Wrapper>
        <Slider {...settings}>
          {slides.map((slide, i) => (
            <AdSlide
              key={i}
              buttonText={slide.button}
              buttonClick={slide.buttonClick}
              message={slide.message}
            />
          ))}

          {/* {data
                        .sort(
                            (a, b) =>
                                distance(latitude, longitude, a.lat, a.lng) -
                                distance(latitude, longitude, b.lat, b.lng)
                        )
                        .map((location) => (
                            <Slide
                                key={location.id}
                                onClick={() => handleClick(location)}
                            >
                                <h2>{capitalize(location.title)}</h2>
                                {getHours(location) && (
                                    <span>
                                        <strong>
                                            Estimated waiting room time:
                                        </strong>{" "}
                                        {getHours(location)}
                                    </span>
                                )}
                            </Slide>
                        ))} */}
        </Slider>
      </Wrapper>
    </>
  );
};

export default AdCarousel;
