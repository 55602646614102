import React from "react";
import styled from "styled-components";
import Wrapper from "./styled/Wrapper";
import Label from "./styled/Label";
import seat from "../images/chair.png";
import seatFilled from "../chair-filled.png";
import clock from "../images/clock.png";

const ColorOverlay = styled.div`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${({ color }) => `hsl(${color} 75% 50%)`};
    mix-blend-mode: screen;
  }
`;

const SeatData = ({ waitTime }) => {
  const options = [30, 60, 120, 150, 180, 240, 360];

  return (
    <Wrapper>
      {options.map((option, i) => {
        return (
          <Label selected={waitTime} key={option}>
            <ColorOverlay color={Math.abs(waitTime / 3.6 - 100)}>
              <img
                src={option <= waitTime ? seatFilled : seat}
                alt={option <= waitTime ? "Seat Filled" : "Empty Seat"}
              />
            </ColorOverlay>
          </Label>
        );
      })}
      <Label>
        <div>
          <img src={clock} alt="" />
        </div>
      </Label>
    </Wrapper>
  );
};

export default SeatData;
