import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import Button from "components/styled/Button";
import Loading from "components/Loading";

const Wrapper = styled.div`
  overflow-y: hidden;
  max-height: ${({ $show }) => ($show ? "100px" : 0)};
  transition: 0.3s max-height ease-in-out;
`;

const Form = styled.form`
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
  gap: 0.75rem;

  & > div {
    flex: 1;
  }
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
`;

const Input = styled.input`
  display: block;
  width: 100%;
  background: #f6f6f6;
  border: 0;
  border-radius: 5px;
  height: 34px;
  font-size: 17px;
  font-weight: 400;
`;

const Error = styled.span`
  display: block;
  color: crimson;
  padding-bottom: 5px;
  font-size: 14px;
`;

const DiscountCode = ({ show, setTrial }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = ({ code }) => {
    setLoading(true);
    const studentDiscount = "STUDENT7";
    setError(false);
    setTrial(false);
    setSubmitted(false);

    setTimeout(() => {
      if (code === studentDiscount) {
        setSubmitted(true);
        setError(false);
        setTrial(true);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    }, 500);
  };

  return (
    <Wrapper $show={show}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Label htmlFor="code">Discount Code</Label>
          <Input
            id="code"
            type="code"
            {...register("code", {
              required: "A discount code is required.",
            })}
          />
        </div>

        <Button
          style={{ width: "5rem", marginBottom: 0 }}
          $small
          type="submit"
          disabled={loading}
        >
          {loading ? <Loading /> : "Apply"}
        </Button>
      </Form>
      <p style={{ marginTop: "0.25rem", marginBottom: "0.75rem" }}>
        {errors.code && <Error>{errors.code.message}</Error>}
        {submitted && (
          <Error style={{ color: "#1B4584" }}>
            Your code has been applied.
          </Error>
        )}
        {error && <Error>The code you entered is invalid or expired.</Error>}
      </p>
    </Wrapper>
  );
};

export default DiscountCode;
