import React from "react";
import styled from "styled-components";
import logo from "../../logo-small.png";
import Button from "./Button";
const LogoWrapper = styled.div`
  img {
    height: 32px;
    width: auto;
  }
`;

const Logo = () => (
  <LogoWrapper>
    <img src={logo} alt="HealthDesk" />
  </LogoWrapper>
);

export default Logo;
