import React, { useEffect } from "react";
import styled from "styled-components";
import EmergencySearch from "../EmergencySearch";
import InputField from "../styled/InputField";
import Input from "../styled/Input";
import Error from "../styled/Error";
import Search from "components/Search";
import FormFacility from "./FormFacility";

const InputWrapper = styled.div`
  border-top: ${({ $top }) => ($top ? "0.55px solid #c8c7cc" : "none")};
  padding-top: ${({ $top }) => ($top ? "10px" : "0")};
  border-bottom: 0.55px solid #c8c7cc;
  margin-bottom: 10px;
`;

const TwoColumn = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
  /* color: #000; */
`;

const FormAccessLevel = ({
  register,
  unregister,
  setHospitalId,
  setCoords,
  setAddress,
  setValue,
  watch,
  errors,
  control,
}) => {
  const watchLevel = watch("level", "");

  return (
    <>
      <InputWrapper>
        <Label>Desired Access Level:</Label>

        <TwoColumn>
          <input
            type="radio"
            id="patient"
            value="patient"
            {...(register &&
              register("level", {
                required: "Desired access level required.",
              }))}
          />
          <Label htmlFor="patient" $large>
            Medical Superintelligence
          </Label>
        </TwoColumn>

        {/* <TwoColumn>
          <input
            type="radio"
            id="professional"
            value="professional"
            {...(register &&
              register("level", {
                required: "Desired access level required.",
              }))}
          />
          <Label htmlFor="professional" $large>
            Professional-level Access
          </Label>
        </TwoColumn> */}

        <TwoColumn>
          <input
            type="radio"
            id="facility"
            value="facility"
            {...(register &&
              register("level", {
                required: "Desired access level required.",
              }))}
          />
          <Label htmlFor="facility" $large>
            Facility-level Access (manage map entries)
          </Label>
        </TwoColumn>

        {errors.level && <Error>{errors.level.message}</Error>}
      </InputWrapper>

      {/* Facility level access */}
      {watchLevel === "facility" ? (
        <FormFacility
          register={register}
          unregister={unregister}
          setHospitalId={setHospitalId}
          setCoords={setCoords}
          setAddress={setAddress}
          setValue={setValue}
          watch={watch}
          errors={errors}
          control={control}
        />
      ) : null}

      {/* Patient level access */}
      {watchLevel === "patient" ? (
        <div>
          <p>Medical Superintelligence includes:</p>
          <ul>
            <li>Symptom review</li>
            <li>Medication issues</li>
            <li>Disease briefings</li>
            <li>Medical Superintelligence</li>
            <li>$19/day, $24/month, or $199/year</li>
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default FormAccessLevel;
