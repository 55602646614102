import React, { useContext } from "react";
import styled from "styled-components";
import { AuthContext } from "./Auth";
import { FaBars } from "react-icons/fa";
import Logo from "components/styled/Logo";
import CloseButton from "components/styled/CloseButton";

const MainMenuWrapper = styled.div`
  position: fixed;
  width: 100vw;
  max-width: 400px;
  top: 0;
  left: ${({ $visible }) => ($visible ? 0 : "-400px")};
  bottom: 0;
  background-color: #eee;
  z-index: 999;
  transition: left 200ms;
`;

const Container = styled.div`
  padding: 5px;
`;

const MenuButtonWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 0.675em 0.875em 0.675em 0;
  cursor: pointer;
`;

const NavButton = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
  font-size: 15px;
  text-align: center;
  background: none;
  border: none;
  color: ${(props) =>
    props.disabled
      ? "#ccc"
      : props.current
      ? props.theme.colors.primary
      : "#888"};
  padding: 0.7em;
  line-height: 1.2;
  transition: color 200ms ease-in-out;

  @media (min-width: 768px) {
    font-size: 15px;
  }
`;
const Credits = styled.p`
  bottom: 0;
  position: absolute;
`;
const MenuButton = ({ toggleMenu, visible }) => (
  <>
    <MenuButtonWrapper onClick={toggleMenu}>
      <FaBars size={18} color="#1B4584" />
    </MenuButtonWrapper>
    <MainMenu visible={visible} toggleMenu={toggleMenu} />
  </>
);

const MainMenu = ({ visible, toggleMenu }) => {
  const { user } = useContext(AuthContext);

  return (
    <MainMenuWrapper $visible={visible}>
      <CloseButton onClick={toggleMenu} />
      <Container>
        <Logo />
      </Container>
      <Container>
        {!user ? (
          <NavButton href="/register" rel="noopener noreferrer">
            Add a Facility
          </NavButton>
        ) : null}

        <NavButton href="/register/" target="_blank" rel="noopener noreferrer">
          Medical SuperIntelligence
        </NavButton>
        <NavButton
          href="https://md3c.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </NavButton>
        <NavButton
          href="https://interlockhealth.com/healthcard-basic/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Medication Discounts
        </NavButton>
        <NavButton
          href="/terms-of-use/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </NavButton>
        <NavButton
          href="/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </NavButton>
        <NavButton href="/">HealthDesk</NavButton>
        <Credits>
          This site is protected by reCAPTCHA and the Google{" "}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
          <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
          apply.
        </Credits>
      </Container>
    </MainMenuWrapper>
  );
};

export default MenuButton;
