import Leaflet from "leaflet";
import React, { useState, useEffect } from "react";
import usePosition from "../../../hooks/usePosition";
import styled from "styled-components";
import Marker from "./Marker";
import Loading from "../../Loading";
import VaccineSignup from "./VaccineSignup";
import AdCarousel from "../../AdCarousel";
import Modal from "./Modal";
import Header from "components/Header";
import { db } from "../../../firebase";
import { MapContainer, TileLayer, Rectangle } from "react-leaflet";
import { useMap } from "react-leaflet/hooks";

const MapWrapper = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  /* height: 100dvh; */
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 960px;
  margin: 0 auto;
`;

const SimpleMap = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { latitude, longitude, error } = usePosition();
  const [view, setView] = useState();
  const [coords, setCoords] = useState();
  const [filter, setFilter] = useState("all");
  const [searchLoaded, setSearchLoaded] = useState(true);
  const [subdomain, setSubdomain] = useState();
  const [facility, setFacility] = useState();
  const [currentRegion, setCurrentRegion] = useState();
  const [regional, setRegional] = useState();
  const [rectangle, setRectangle] = useState();
  const [showPopup, setShowPopup] = useState(false);

  const [modalOpen, setModalOpen] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const updateMap = (newCoords, openFacility) => {
    setSearchLoaded(false);
    setView();
    setCoords([newCoords.lat, newCoords.lng]);

    // bounds of map
    const bounds = {
      north: newCoords.lat + 0.35,
      south: newCoords.lat - 0.35,
      east: newCoords.lng + 0.35,
      west: newCoords.lng - 0.35,
    };

    setRectangle([
      [bounds.north, bounds.east],
      [bounds.south, bounds.west],
    ]);

    // const locationIds = locationsJson
    //   .filter(
    //     (location) =>
    //       location.lat < bounds.north &&
    //       location.lat > bounds.south &&
    //       location.lng < bounds.east &&
    //       location.lng > bounds.west
    //   )
    //   .map((location) => location.id);

    getLocations(bounds, openFacility);
  };

  const getLocations = async (bounds, openFacility) => {
    const latLocations = [];
    const lngLocations = [];

    await Promise.all([
      db
        .collection("locations")
        .where("lat", "<", bounds.north)
        .where("lat", ">", bounds.south)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            if (doc.data().lat) {
              latLocations.push(doc.data());
            }
          });
        }),
      db
        .collection("locations")
        .where("lng", "<", bounds.east)
        .where("lng", ">", bounds.west)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            if (doc.data().lat) {
              lngLocations.push(doc.data());
            }
          });
        }),
    ]).then(() => {
      const locations = latLocations.filter((locationLat) =>
        lngLocations.some((locationLng) => locationLat.id === locationLng.id)
      );

      if (locations.length) {
        const openLocations = locations.filter(
          (location) => location.vaccineQueueCap
        );
        if (openLocations.length === 0) setShowPopup(true);

        setData(locations);

        if (openFacility) {
          const {
            title,
            id,
            type,
            textSequence,
            vaccineQueueNumber,
            vaccineQueueCap,
            vaccinePatientInfo,
            vaccineAge,
            lat,
            lng,
          } = locations.filter((location) => location.id == openFacility)[0];

          setModalVisible(true);
          setModalOpen({
            title,
            id,
            type,
            filter,
            setModalOpen,
            setModalVisible,
            textSequence,
            vaccineQueueNumber,
            vaccineQueueCap,
            vaccinePatientInfo,
            vaccineAge,
            location: { latitude: lat, longitude: lng },
          });
        }

        setSearchLoaded(true);
        setLoading(false);
      } else {
        setData([]);
        setSearchLoaded(true);
        setLoading(false);
        setShowPopup(true);
      }
    });
  };

  // Onload, check if we're on a subdomain...
  useEffect(() => {
    switch (window.location.host.split(".")[0]) {
      case "chicago":
        setSubdomain("chicago");
        updateMap({ lat: 41.881832, lng: -87.623177 });
        break;
      case "nyc":
        setSubdomain("nyc");
        updateMap({ lat: 40.73061, lng: -73.935242 });
        break;
      case "desmoines":
        setSubdomain("desmoines");
        updateMap({ lat: 41.619549, lng: -93.598022 });
        break;
      default:
        setSubdomain();
    }

    const path = window.location.pathname;
    const segments = path.split("/");
    const id = String(segments.pop());

    if (id) {
      switch (id) {
        case "nm":
          setCurrentRegion("nm");
          break;
        case "wray":
          setCurrentRegion("wray");
          break;
        default:
          const info = atob(id).split(",");
          setFacility(info[2]);
          updateMap({ lat: Number(info[0]), lng: Number(info[1]) }, info[2]);
      }
    }
  }, []);

  useEffect(() => {
    // Get locations when geolocation has loaded

    if (latitude && !subdomain && !facility) {
      // bounds of map
      const bounds = {
        north: latitude + 0.35,
        south: latitude - 0.35,
        east: longitude + 0.35,
        west: longitude - 0.35,
      };

      setRectangle([
        [bounds.north, bounds.east],
        [bounds.south, bounds.west],
      ]);

      getLocations(bounds);
    }
  }, [latitude]);

  const DoTracking = () => {
    var a = String(Math.random()) * 10000000000000;
    new Image().src =
      "https://pubads.g.doubleclick.net/activity;xsp=4736959;ord=" + a + "?";
    return (
      <img
        src={"https://pubads.g.doubleclick.net/activity;xsp=4736959;ord=1?"}
        width={1}
        height={1}
        border={0}
      ></img>
    );
  };

  return (
    <MapWrapper>
      {loading ? (
        <Loading page />
      ) : (
        <div style={{ height: "100vh", width: "100%" }}>
          <Header
            data={data}
            filter={filter}
            setFilter={setFilter}
            updateMap={updateMap}
            searchLoaded={searchLoaded}
            currentRegion={currentRegion}
            regional={regional}
            setRegional={setRegional}
            vaccine
          />
          <MapContainer
            attributionControl={false}
            style={{
              height: "100vh",
              width: "100%",
              zIndex: 0,
            }}
            center={view || coords || [latitude, longitude]}
            zoom={11}
            animate={true}
          >
            <TileLayer url="https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=Qsfg2BU2JRzkowxU2Rw0" />
            {data.map(
              ({
                id,
                lat,
                lng,
                title,
                type,
                vaccineQueueCap,
                vaccineQueueNumber,
                vaccineTextSequence,
                vaccinePatientInfo,
                vaccineAge,
                region,
                users,
              }) => {
                return (
                  <Marker
                    lat={lat}
                    lng={lng}
                    title={title}
                    type={type}
                    vaccineQueueCap={vaccineQueueCap}
                    vaccineQueueNumber={vaccineQueueNumber || 1}
                    vaccinePatientInfo={vaccinePatientInfo}
                    vaccineAge={vaccineAge}
                    textSequence={vaccineTextSequence}
                    key={id}
                    id={id}
                    filter={filter}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    setModalVisible={setModalVisible}
                    regional={regional}
                    currentRegion={currentRegion}
                    region={region}
                    active={users?.length > 0}
                  />
                );
              }
            )}
            <Rectangle
              bounds={rectangle}
              color="#ff0000"
              fillColor="transparent"
            />
          </MapContainer>
          <AdCarousel
            data={data}
            setView={setView}
            setModalOpen={setModalOpen}
            setModalVisible={setModalVisible}
            latitude={latitude}
            longitude={longitude}
          />
          {latitude !== undefined ? (
            <Modal
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              userLocation={{
                latitude: coords ? coords[0] : latitude,
                longitude: coords ? coords[1] : longitude,
              }}
              error={error}
            ></Modal>
          ) : null}
          {showPopup ? (
            <VaccineSignup latitude={latitude} longitude={longitude} />
          ) : null}
        </div>
      )}
      <DoTracking></DoTracking>
    </MapWrapper>
  );
};

export default SimpleMap;
