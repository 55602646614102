import React, { useContext, useState } from "react";
import firebase from "firebase/compat/app";
import styled from "styled-components";
import { Navigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import {
  FaClock,
  FaCalendar,
  FaUsers,
  FaUserCircle,
  FaMap,
  FaArrowLeft,
  FaSignOutAlt,
} from "react-icons/fa";
import { FaPenToSquare } from "react-icons/fa6";
import { BsChatDotsFill, BsListCheck, BsPersonLinesFill } from "react-icons/bs";

import Logo from "../styled/Logo";
import { AuthContext } from "components/Auth";

const DesktopWrapper = styled.div`
  @media (min-width: 768px) {
    background-color: #eee;
    width: 330px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;

    div {
      position: relative;
    }
  }
`;

const TopNav = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  background-color: #eee;
  padding: 5px 5px 3px;

  .links {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .links:first-child {
    margin-left: 0;
  }

  button {
    cursor: pointer;
    padding: 10px 5px;
    background: none;
    border: none;
    color: ${(props) => props.theme.colors.primary};

    a {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const NavWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 2;
  background-color: #eee;
  border-top: 1px solid #ccc;
`;

const NavInner = styled.div`
  display: flex;
  max-width: 450px;
  margin: 0 auto;
  overflow-x: scroll;

  @media (min-width: 768px) {
    display: block;
    overflow-x: hidden;
  }
`;

const NavButton = styled(({ current, toplevel, ...props }) => (
  <Link {...props} />
))`
  flex: 1;
  font-size: 13px;
  text-align: center;
  background: none;
  border: none;
  color: ${(props) =>
    props.disabled
      ? "#ccc"
      : props.current
      ? props.theme.colors.primary
      : "#888"};
  padding: 0.5em;
  line-height: 1.2;
  transition: color 200ms ease-in-out;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    padding: 0.7em;
    margin-left: ${(props) => (props.toplevel ? 0 : "20px")};
  }

  svg {
    font-size: 22px;
    fill: ${(props) =>
      props.disabled
        ? "#ccc"
        : props.current
        ? props.theme.colors.primary
        : "#888"};
    display: block;
    margin: 0 auto 0.3em;
    transition: fill 200ms ease-in-out;

    @media (min-width: 768px) {
      display: inline;
      margin: 0;
      margin-right: 10px;
    }
  }
`;

const NavCategoryButton = styled.button`
  display: none;
  flex: 1;
  font-size: 14px;
  text-align: center;
  background: none;
  border: none;
  color: ${({ $isOpen, theme }) => ($isOpen ? theme.colors.primary : "#888")};
  padding: 0.7em;
  line-height: 1.2;
  transition: color 200ms ease-in-out;

  @media (min-width: 768px) {
    display: block;
  }
`;

const NavCategoryWrapper = styled.div`
  display: flex;
  max-height: initial;
  overflow: hidden;
  transition: max-height 200ms;
  flex-basis: 80%;

  @media (min-width: 768px) {
    display: block;
    max-height: ${({ $isOpen }) => ($isOpen ? "200px" : 0)};
  }
`;

const NavCategory = ({ title, open, children }) => {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <>
      <NavCategoryButton onClick={() => setIsOpen(!isOpen)} $isOpen={isOpen}>
        {title}
      </NavCategoryButton>
      <NavCategoryWrapper $isOpen={isOpen}>{children}</NavCategoryWrapper>
    </>
  );
};

const DashboardNav = ({ title, active, only_vaccines }) => {
  const { user, subscription, userLoading } = useContext(AuthContext);
  let location = useLocation();

  const handleLogout = async () => {
    await firebase.auth().signOut();
    return <Navigate to="/login/" />;
  };

  return (
    <DesktopWrapper>
      <TopNav>
        <div className="links">
          <Link to="/">
            <button>
              {/* {location.pathname.split("/")[2] === "vaccine" ? (
              <a href="/vaccine">Map</a>
            ) : ( */}
              <FaArrowLeft size="18" color="#1B4584" />
              {/* )} */}
            </button>
          </Link>
        </div>

        <div className="logo">
          <Logo />
        </div>

        <div className="links">
          {subscription ? (
            <Link to="/dashboard/account">
              <button>
                <FaUserCircle size="18" color="#1B4584" />
              </button>
            </Link>
          ) : (
            <button onClick={handleLogout}>
              <FaSignOutAlt size="18" color="#1B4584" />
            </button>
          )}
        </div>
      </TopNav>
      {/* If the user has verified email and has subscribed, show navigation */}
      {subscription === "facility" ? (
        <NavWrapper>
          <NavInner>
            <NavCategory
              title="HealthDesk"
              open={location.pathname.split("/")[2] !== "vaccine"}
            >
              <NavButton
                to="/dashboard"
                current={location.pathname === "/dashboard"}
                className={only_vaccines ? "hidden-button" : null}
              >
                <FaClock />
                Waiting Room
              </NavButton>
              <NavButton
                to="/dashboard/schedule"
                current={location.pathname === "/dashboard/schedule"}
                className={only_vaccines ? "hidden-button" : null}
              >
                <FaCalendar />
                Schedule
              </NavButton>
              <NavButton
                to="/dashboard/queue"
                current={location.pathname === "/dashboard/queue"}
                className={only_vaccines ? "hidden-button" : null}
              >
                <FaUsers />
                Queue
              </NavButton>
              <NavButton
                to="/dashboard/sequence"
                current={location.pathname === "/dashboard/sequence"}
              >
                <BsChatDotsFill />
                Healthcare Texts
              </NavButton>
            </NavCategory>
            <NavButton
              to="/dashboard/account"
              current={location.pathname === "/dashboard/account"}
              toplevel
            >
              <FaUserCircle />
              Account
            </NavButton>
          </NavInner>
        </NavWrapper>
      ) : null}
    </DesktopWrapper>
  );
};

export default DashboardNav;
