import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import styled from "styled-components";
import firebaseApp, { db } from "../../firebase";
import { useForm } from "react-hook-form";
import { useStripe } from "@stripe/react-stripe-js";
import Button from "../styled/Button";
import Error from "../styled/Error";
import DiscountCode from "./DiscountCode";

const InputWrapper = styled.div`
  border-top: ${(props) => (props.top ? "0.55px solid #c8c7cc" : "none")};
  padding-top: ${(props) => (props.top ? "10px" : "0")};
  border-bottom: 0.55px solid #c8c7cc;
  margin-bottom: 10px;

  h2 {
    font-size: 1.25rem !important;
    text-align: left !important;
  }

  p {
    color: #1b4584;
    margin-bottom: 2.5rem;
  }
`;

const TwoColumn = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
`;

const Pricing = ({ uid, role }) => {
  const stripe = useStripe();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState();
  const [codeOpen, setCodeOpen] = useState(false);
  const [trial, setTrial] = useState(false);
  const [prices, setPrices] = useState({});

  const getPlans = async () => {
    if (role)
      db.collection("plans")
        .where("active", "==", true)
        .where("role", "==", role)
        .get()
        .then((querySnapshot) =>
          querySnapshot.forEach(async (doc) => {
            const productData = doc.data();

            db.collection("plans")
              .doc(doc.id)
              .collection("prices")
              .where("active", "==", true)
              .get()
              .then((priceSnap) => {
                let priceList = [];

                priceSnap.forEach((price) => {
                  const priceData = price.data();

                  const roundNumber =
                    String(priceData.unit_amount).slice(-2) === "00";
                  const newPrice = {
                    id: price.id,
                    role: productData.role,
                    price: `$${
                      roundNumber
                        ? priceData.unit_amount / 100
                        : (priceData.unit_amount / 100).toFixed(2)
                    }`,
                    interval: priceData.interval,
                  };

                  priceList[newPrice.interval] = newPrice;
                });

                setPrices(priceList);
              });
          })
        );
  };

  useEffect(() => {
    getPlans();
  }, [role]);

  const sendToCheckout = ({ plan }) => {
    setLoading(true);

    const checkout =
      role === "patient"
        ? {
            price: plan,
            trial_period_days: trial ? 7 : 1,
            billing_address_collection: "auto",
            payment_method_collection: "if_required",
            success_url: window.location.origin + "/dashboard", // redirect user to this screen after
            cancel_url: window.location.href,
          }
        : {
            price: plan,
            success_url: window.location.origin + "/dashboard", // redirect user to this screen after
            cancel_url: window.location.href,
          };

    db.collection("users")
      .doc(uid)
      .collection("checkout_sessions")
      .add(checkout)
      .then((docRef) => {
        docRef.onSnapshot((snap) => {
          const { error, sessionId } = snap.data();
          if (error) {
            alert(`An error occurred: ${error.message}`);
          }

          if (sessionId) {
            stripe.redirectToCheckout({ sessionId });
          }
        });
      });
  };

  return Object.keys(prices).length > 0 ? (
    <>
      <InputWrapper>
        <h2>Subscribe now</h2>

        {role === "patient" ? (
          <>
            <p style={{ marginBottom: 0 }}>
              <strong>For all our plans, get a 24 hour free trial.</strong> No
              credit card required.
            </p>
            <div style={{ marginTop: "15px", marginBottom: "20px" }}>
              <p
                style={{
                  color: "#117aca",
                  cursor: "pointer",
                  marginBottom: "10px",
                }}
                onClick={() => setCodeOpen(!codeOpen)}
              >
                Have a discount code?
              </p>
              <DiscountCode show={codeOpen} setTrial={setTrial} />
            </div>
          </>
        ) : null}
      </InputWrapper>

      <form onSubmit={handleSubmit(sendToCheckout)}>
        <Price interval={"Monthly"} price={prices.month} register={register} />
        <Price interval={"Yearly"} price={prices.year} register={register} />
        <Price interval={"Daily"} price={prices.day} register={register} />
        {/* {errors.plan && <Error>{errors.plan.message}</Error>} */}
        <Button type="submit" disabled={role === undefined || loading}>
          {loading ? <Loading /> : "Select Plan"}
        </Button>
      </form>
    </>
  ) : (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Loading page />
    </div>
  );
};

export default Pricing;

const PriceWrapper = styled.div`
  padding: 12px 14px 8px;
  background-color: #f6f6f6;
  margin-bottom: 0.5rem;

  .small {
    color: #117aca;
    display: block;
    font-size: 12px;
    margin-bottom: 0.25rem;
  }

  .trial {
    color: #1b4584;
  }

  .bold {
    font-size: 18px;
    font-weight: bold;
  }

  .price {
    font-size: 24px;
  }
`;

const Price = ({ interval, price, register }) => {
  if (price === undefined) return;

  return (
    <PriceWrapper>
      <TwoColumn key={price.id}>
        <Label htmlFor={price.id}>
          <span className="small">{interval} plan</span>
          <span className="trial">
            <span className="bold">
              <span className="price">{price.price}</span>/{price.interval}.{" "}
            </span>
            Cancel anytime.
          </span>
        </Label>
        <input
          type="radio"
          id={price.id}
          value={price.id}
          {...(register &&
            register("plan", {
              required: "Pricing plan required.",
            }))}
        />
      </TwoColumn>
    </PriceWrapper>
  );
};
