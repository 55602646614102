import React, { useState } from "react";
import styled from "styled-components";
import useSearch from "hooks/useSearch";
import Loading from "components/Loading";

export default ({
  setCoords,
  setAddress,
  addressField = false,
  searchLoaded = true,
  open = true,
  updateLocation,
}) => {
  const address = useSearch("");
  const [hitsOpen, setHitsOpen] = useState(true);

  return (
    <>
      <Wrapper $open={open}>
        <Input
          placeholder={`Search for ${
            addressField ? "an" : "a city or"
          } address`}
          value={address.value}
          onChange={address.onChange}
          onFocus={() => {
            setTimeout(() => {
              setHitsOpen(true);
            }, 100);
          }}
          onBlur={() => {
            setTimeout(() => {
              setHitsOpen(false);
            }, 200);
          }}
          autoFocus
        />
        {address.loading || !searchLoaded ? <Loading search /> : null}
      </Wrapper>
      {address.suggestions?.length > 0 && (
        <SuggestionWrapper className="suggestions" $hitsOpen={hitsOpen}>
          {address.suggestions.map((suggestion, index) => {
            return (
              <Suggestion
                key={index}
                onClick={() => {
                  const { place_name } = suggestion;
                  const lat = suggestion.center[1];
                  const lng = suggestion.center[0];

                  address.setValue(place_name);
                  setAddress && setAddress(place_name);
                  setCoords && setCoords({ lat, lng });
                  address.setSuggestions([]);
                  updateLocation && updateLocation({ lat, lng }, place_name);
                }}
              >
                <span className="title">{suggestion.text}</span>
                <span className="place">{suggestion.place_name}</span>
              </Suggestion>
            );
          })}
        </SuggestionWrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  /* margin: 0 auto; */
  position: relative;
  max-height: ${({ $open }) => ($open ? "40px" : 0)};
  overflow-y: hidden;
  overflow-x: hidden;
  transition: max-height 200ms;
`;

const Input = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  padding: 10px 0;
  position: relative;
  display: grid;
  justify-self: center;
  font-size: 16px;

  &:focus {
    outline: none;
  }
`;

const SuggestionWrapper = styled.div`
  background: white;
  position: absolute;
  width: calc(100% + 10px);
  padding: 10px 0;
  margin-left: -5px;
  z-index: 99;
  visibility: ${({ $hitsOpen }) => ($hitsOpen ? "visible" : "hidden")};
`;

const Suggestion = styled.p`
  cursor: pointer;
  color: #1b4584;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-align: left !important;
  max-width: 100%;
  padding: 7px 20px;
  margin: 0 !important;

  .title {
    display: block;
    font-weight: 700 !important;
  }

  .place {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  &:hover {
    background-color: #f9f9f9;
  }
`;
