import React from "react";
import styled from "styled-components";

const LegendWrapper = styled.div`
  display: ${({ $schedule }) => ($schedule ? "none" : "flex")};
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 10px;
`;

const Legend = ({ schedule }) => (
  <LegendWrapper $schedule={schedule}>
    <strong>empty</strong>
    <strong>half</strong>
    <strong>full</strong>
    <strong>overflowing</strong>
  </LegendWrapper>
);

export default Legend;
