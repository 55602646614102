import React, { useState, useContext } from "react";
import Loading from "./Loading";
import { AuthContext } from "./Auth";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import firebaseApp from "../firebase";
import { useForm } from "react-hook-form";

import PageWrapper from "./styled/PageWrapper";
import LogoLarge from "./styled/LogoLarge";
import Button from "./styled/Button";
import ButtonCaptcha from "./styled/ButtonCaptcha";
import Text from "./styled/Text";
import TextLink from "./styled/TextLink";
import TextAnchor from "./styled/TextAnchor";
import Columns from "./styled/Columns";

const InputWrapper = styled.div`
  border-top: ${(props) => (props.top ? "0.55px solid #c8c7cc" : "none")};
  padding-top: ${(props) => (props.top ? "10px" : "0")};
  border-bottom: 0.55px solid #c8c7cc;
  margin-bottom: 10px;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
  /* color: #000; */
`;

const Input = styled.input`
  display: block;
  width: 100%;
  background: transparent;
  border: 0;
  height: 40px;
  font-size: 17px;
  font-weight: 400;
`;

const Error = styled.span`
  display: block;
  color: crimson;
  padding-bottom: 5px;
  font-size: 14px;
`;

const LoginForm = (onLogin) => {
  const isFunction = (functionToCheck) => {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    );
  };
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [firebaseErrors, setFirebaseErrors] = useState("");

  const onSubmit = ({ email, password }) => {
    setLoading(true);

    // Create user and create database entry for user/booklist
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        //I have no idea why the first code works on the login page and everywhere else needs the second code
        if (isFunction(onLogin)) {
          onLogin();
        } else if (isFunction(onLogin.onLogin)) {
          onLogin.onLogin();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        let errorMessage;
        switch (error.code) {
          case "auth/invalid-credential":
            errorMessage =
              "Your email address or password is incorrect. Please try again.";
            break;
          default:
            errorMessage = error.message;
        }

        setFirebaseErrors(errorMessage);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper>
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          type="email"
          {...register("email", {
            required: "Email is required.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address.",
            },
          })}
        />
        {errors.email && <Error>{errors.email.message}</Error>}
      </InputWrapper>

      <InputWrapper>
        <Label htmlFor="password">Password</Label>
        <Input
          id="password"
          type="password"
          {...register("password", { required: "Password is required." })}
        />
        {errors.password && <Error>{errors.password.message}</Error>}
      </InputWrapper>

      {firebaseErrors && <Error>{firebaseErrors}</Error>}
      <Button type="submit" disabled={loading}>
        {loading ? <Loading /> : "Log In"}
      </Button>
    </form>
  );
};

const Login = () => {
  const { user } = useContext(AuthContext);

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  return user !== null ? (
    <Loading />
  ) : (
    <PageWrapper>
      <LogoLarge />

      <LoginForm
        onLogin={() => {
          return <Navigate to="/dashboard" />;
        }}
      ></LoginForm>
      <Link to="/register">
        <ButtonCaptcha $secondary>Create New Account</ButtonCaptcha>
      </Link>

      <Text $center>
        Forgot your password? <Link to="/reset-password">Reset password</Link>
      </Text>
      <Text $center>
        <Link to="/">Return to Map</Link>
      </Text>
      <Columns $main>
        <TextAnchor href="/terms-of-use" target="_blank">
          Terms of Use
        </TextAnchor>
        <TextAnchor href="/privacy-policy" target="_blank">
          Privacy Policy
        </TextAnchor>
      </Columns>
    </PageWrapper>
  );
};

export { Login, LoginForm };
