import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import Dashboard from "./components/Dashboard";
import Map from "./components/Map";
import VaccineMap from "./components/vaccine/map/Map";
import PatientRegistration from "components/vaccine/queue/PatientRegistration";
import PatientQuestionnaire from "components/vaccine/queue/PatientQuestionnaire";
//import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Registration from "./components/registration/Registration";
import { Login } from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import PrivacyPolicy from "components/registration/PrivacyPolicy";
import { AuthProvider } from "./components/Auth";
import styled, { ThemeProvider } from "styled-components";
import "./App.css";
import { loadReCaptcha } from "react-recaptcha-v3";
import theme from "./components/helpers/theme";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import InfoDump from "./components/InfoDump";
import TermsOfUse from "components/registration/TermsOfUse";
import Baa from "components/registration/baa";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

if (process.env.NODE_ENV === "production")
  Sentry.init({
    dsn: "https://e60e858a354a42d88a5d71f742e1e77e@o384246.ingest.sentry.io/5215159",
  });

const AppWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
`;

function App() {
  const DoTracking = () => {
    var a = String(Math.random()) * 10000000000000;
    new Image().src =
      "https://pubads.g.doubleclick.net/activity;xsp=4736959;ord=" + a + "?";
    return <React.Fragment></React.Fragment>;
  };
  useEffect(() => {
    loadReCaptcha("6LdFylEaAAAAAFxT6Keio4PQU5sg1jhvn7BE5mPq", () => {});
  });

  const api_regex = /^\/api\/.*/;
  // if using "/api/" in the pathname, don't use React Router
  if (api_regex.test(window.location.pathname)) {
    return <div />; // must return at least an empty div
  } else {
    // use React Router
    return (
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <BrowserRouter>
            <AppWrapper className="App">
              <Routes>
                <Route path="/info_dump" element={<InfoDump />} />

                <Route
                  path="/dashboard/*"
                  element={
                    <Elements stripe={stripePromise}>
                      <Dashboard />
                    </Elements>
                  }
                />
                <Route path="/register" element={<Registration />} />
                <Route
                  path="/login"
                  element={<Login timestamp={new Date().toString()} />}
                />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/registration" element={<PatientRegistration />} />
                <Route
                  path="/vaccine/registration"
                  element={<PatientRegistration vaccine />}
                />
                <Route
                  path="/vaccine/questionnaire"
                  element={<PatientQuestionnaire vaccine />}
                />
                <Route path="/vaccine" element={<VaccineMap />} />
                <Route path="/" element={<Map />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/baa" element={<Baa />} />
              </Routes>
            </AppWrapper>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    );
  }
}

export default App;
