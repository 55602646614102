import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { db } from "../../firebase";
import firebase from "firebase/compat/app";
import styled from "styled-components";

import Loading from "../Loading";
import InputField from "../styled/InputField";
import Button from "../styled/Button";
import Text from "../styled/Text";
import TextAnchor from "../styled/TextAnchor";
import SeatRating from "../SeatRating";

const InputWrapper = styled.div`
  border-top: ${(props) => (props.top ? "0.55px solid #c8c7cc" : "none")};
  padding-top: ${(props) => (props.top ? "10px" : "0")};
  border-bottom: 0.55px solid #c8c7cc;
  margin-bottom: 10px;
`;

const TwoColumn = styled.div`
  display: flex;
  margin-bottom: 5px;

  select:last-child,
  div:last-child {
    margin-left: auto;
  }
`;

const Label = styled.label`
  margin-top: 15px;
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
  color: ${({ $success, theme }) => ($success ? theme.colors.primary : "#000")};
  font-weight: ${({ $bold }) => ($bold ? "700" : "normal")};
  text-align: ${({ $bold }) => ($bold ? "center" : "left")};
  padding-bottom: ${({ $bold }) => ($bold ? "5px" : 0)};
`;

const Input = styled.input`
  display: block;
  width: 100%;
  background: transparent;
  border: 0;
  height: 40px;
  font-size: 17px;
  font-weight: 400;
`;

const Error = styled.span`
  display: block;
  color: crimson;
  padding-bottom: 5px;
`;

const AdminTimeForm = ({ data }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [dbError, setDbError] = useState(false);

  const isChecked = (value) => {
    if (!data[value]) {
      return false;
    }
    return data[value] === "yes";
  };

  const onSubmit = (values) => {
    console.log(values);
    setLoading(true);

    // Submit different data depending if hospital or urgent care
    const dataObject =
      data.type == "Clinic"
        ? {
            date: Date.now(),
            waitTime: values.time,
            lab: values.lab,
            xray: values.xray,
            ultrasound: values.ultrasound,
            ct: values.ct,
            mri: values.mri,
            //   telehealth: values.telehealth,
            customPhone: values.customPhone,
            admin: true,
            dashboard: true,
          }
        : {
            date: Date.now(),
            waitTime: values.time,
            //   beds: values.beds,
            //   icu: values.icu,
            //   telehealth: values.telehealth,
            customPhone: values.customPhone,
            admin: true,
            dashboard: true,
          };

    db.collection("locations")
      .doc(String(data.id))
      .update({
        waitTimes: firebase.firestore.FieldValue.arrayUnion(dataObject),
      })
      .then(function () {
        setLoading(false);
        setSubmitted(true);
      })
      .catch(function (error) {
        setLoading(false);
        setDbError(true);
      });
  };

  return (
    <div className="inner">
      <h1>Waiting Room Current State</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <Label>
            What is the current estimated waiting room volume for your facility?
          </Label>
          <SeatRating register={register} />
          {errors.time && <Error>{errors.time.message}</Error>}
        </InputWrapper>
        {data ? (
          <div>
            {
              data.type == "Clinic" ? (
                <>
                  <Label $bold $large>
                    On Site Capabilities
                  </Label>
                  <TwoColumn>
                    <label>Lab</label>
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="yes"
                          defaultChecked={isChecked("lab")}
                          {...(register && register("lab"))}
                        />{" "}
                        Y
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="no"
                          defaultChecked={!isChecked("lab")}
                          {...(register && register("lab"))}
                        />{" "}
                        N
                      </label>
                    </div>
                  </TwoColumn>

                  <TwoColumn>
                    <label>Xray (plain films)</label>
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="yes"
                          defaultChecked={isChecked("xray")}
                          {...(register && register("xray"))}
                        />{" "}
                        Y
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="no"
                          defaultChecked={!isChecked("xray")}
                          {...(register && register("xray"))}
                        />{" "}
                        N
                      </label>
                    </div>
                  </TwoColumn>

                  <TwoColumn>
                    <label>Ultrasound</label>
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="yes"
                          defaultChecked={isChecked("ultrasound")}
                          {...(register && register("ultrasound"))}
                        />{" "}
                        Y
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="no"
                          defaultChecked={!isChecked("ultrasound")}
                          {...(register && register("ultrasound"))}
                        />{" "}
                        N
                      </label>
                    </div>
                  </TwoColumn>

                  <TwoColumn>
                    <label>CT</label>
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="yes"
                          defaultChecked={isChecked("ct")}
                          {...(register && register("ct"))}
                        />{" "}
                        Y
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="no"
                          defaultChecked={!isChecked("ct")}
                          {...(register && register("ct"))}
                        />{" "}
                        N
                      </label>
                    </div>
                  </TwoColumn>

                  <TwoColumn>
                    <label>MRI</label>
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="yes"
                          defaultChecked={isChecked("mri")}
                          {...(register && register("mri"))}
                        />{" "}
                        Y
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="no"
                          defaultChecked={!isChecked("mri")}
                          {...(register && register("mri"))}
                        />{" "}
                        N
                      </label>
                    </div>
                  </TwoColumn>
                </>
              ) : null
              // <InputWrapper>
              //     <Label>Bed Availability</Label>
              //     <TwoColumn>
              //         <label>General/Intermediate</label>
              //         <select
              //             defaultValue={
              //                 (data.waitTimes &&
              //                     data.waitTimes
              //                         .reverse()
              //                         .find(
              //                             (time) =>
              //                                 time.beds
              //                         ) &&
              //                     data.waitTimes
              //                         .reverse()
              //                         .find(
              //                             (time) =>
              //                                 time.beds
              //                         ).beds) ||
              //                 0
              //             }
              //             name="beds"
              //             ref={register()}
              //         >
              //             {beds.map((bed, i) => (
              //                 <option
              //                     key={`bed-${i}`}
              //                     value={bed}
              //                 >
              //                     {bed}
              //                 </option>
              //             ))}
              //         </select>
              //     </TwoColumn>
              //     <TwoColumn>
              //         <label>
              //             ICU (includes ventilators)
              //         </label>
              //         <select
              //             defaultValue={
              //                 (data.waitTimes &&
              //                     data.waitTimes
              //                         .reverse()
              //                         .find(
              //                             (time) =>
              //                                 time.icu
              //                         ) &&
              //                     data.waitTimes
              //                         .reverse()
              //                         .find(
              //                             (time) =>
              //                                 time.icu
              //                         ).icu) ||
              //                 0
              //             }
              //             name="icu"
              //             ref={register()}
              //         >
              //             {icus.map((icu, i) => (
              //                 <option
              //                     key={`icu-${i}`}
              //                     value={icu}
              //                 >
              //                     {icu}
              //                 </option>
              //             ))}
              //         </select>
              //     </TwoColumn>
              // </InputWrapper>
            }
          </div>
        ) : (
          <Loading page />
        )}
        {/* <InputField
                    type="number"
                    name="telehealth"
                    label="Pre(ER) ID"
                    register={register}
                    defaultValue={data?.telehealth}
                /> */}
        <InputField
          type="tel"
          name="customPhone"
          label="Facility Phone Number (For waiting time update notifications)"
          register={register}
          placeholder="123 456 7890"
          defaultValue={data?.customPhone}
          autoComplete="tel"
        />
        <Button disabled={loading} type="submit">
          {loading ? <Loading /> : "Submit"}
        </Button>
      </form>
      {submitted && !loading && (
        <Text $center>Thank you. Your estimate has been submitted.</Text>
      )}
      {dbError && (
        <Error>Error submitting the form. Please try again later.</Error>
      )}
      <TextAnchor $center href="mailto:support@hlthdsk.com">
        If you have any problems, questions, or suggestions, please contact us
        using our support@hlthdsk.com email address.
      </TextAnchor>
    </div>
  );
};

export default AdminTimeForm;
