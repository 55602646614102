import React, { useState, useContext, useEffect } from "react";
import Loading from "../Loading";
import { AuthContext } from "../Auth";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import styled from "styled-components";
import PlacesAutocomplete from "../PlacesAutocomplete";
import firebaseApp, { db } from "../../firebase";
import { useForm } from "react-hook-form";
import LinkedInTag from "react-linkedin-insight";
import patientVideo from "../../patient.gif";

import Form1 from "./Form1";
import FormAccessLevel from "./FormAccessLevel";

import LogoLarge from "../styled/LogoLarge";
import PageWrapper from "../styled/PageWrapper";
import Input from "../styled/Input";
import Text from "../styled/Text";
import Button from "../styled/Button";
import TextAnchor from "../styled/TextAnchor";
import Error from "../styled/Error";

const Checkbox = ({ name, register, rules, label, errors }) => (
  <>
    <TwoColumn>
      <input
        id={name}
        type="checkbox"
        {...(register && register(name, rules))}
      />
      <Label htmlFor={name} $large $strong>
        {label}
      </Label>
    </TwoColumn>
    {errors[name] ? <Error>{errors[name].message}</Error> : null}
  </>
);

const Form = styled.form`
  position: relative;
`;

const TwoColumn = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
  font-weight: ${({ $strong }) => ($strong ? "700" : "initial")};
  margin-left: 3px;
  /* color: #000; */
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(100% + 5vw);
  width: 350px;
  height: 736px;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2),
    0 4px 18px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 18px 0 rgba(0, 0, 0, 0.19);
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  opacity: ${({ $watchLevel }) => ($watchLevel !== "facility" ? 1 : 0)};
  transition: opacity 300ms ease-in-out;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 1300px) {
    left: calc(100% + 2vw);
  }

  @media screen and (max-width: 1140px) {
    display: none;
  }
`;

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [hospitalId, setHospitalId] = useState();
  const [address, setAddress] = useState("");
  const {
    handleSubmit,
    register,
    unregister,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
  });
  const [firebaseErrors, setFirebaseErrors] = useState("");
  const [coords, setCoords] = useState({});
  const [redirect, setRedirect] = useState(false);

  const watchLevel = watch("level", "");

  const onSubmit = async ({ email, password, level, facility, location }) => {
    setLoading(true);
    setPageLoaded(true);

    // CREATE USER AND ADD TO DATABASE
    // Create user
    firebaseApp
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(({ user }) => {
        const { uid } = user;

        // Send verification email to user
        user
          .sendEmailVerification()
          .then(() => {
            let batch = db.batch();

            // Create user
            const userRef = db.collection("users").doc(uid);

            if (level === "patient") {
              batch.set(userRef, { uid, role: "patient" });
            }

            if (level === "facility") {
              const locationId =
                facility === "hospital" ? String(hospitalId) : uid;
              const locationsRef = db.collection("locations").doc(locationId);

              // Add user information
              batch.set(userRef, {
                uid,
                role: "facility",
                location: locationId,
              });

              // If hospital, add newly created user to authorized users array
              if (facility === "hospital") {
                batch.update(locationsRef, {
                  users: firebase.firestore.FieldValue.arrayUnion(uid),
                });
              }

              // If urgent care, add the new entry to location collection
              if (facility === "urgent-care") {
                batch.set(locationsRef, {
                  id: uid,
                  title: location,
                  lat: coords.lat,
                  lng: coords.lng,
                  address: address,
                  type: "Clinic",
                  users: firebase.firestore.FieldValue.arrayUnion(uid),
                });
              }

              // if (facility === "regional") {
              //   batch.set(locationsRef, {
              //     id: uid,
              //     title: location,
              //     type: 2,
              //     users: firebase.firestore.FieldValue.arrayUnion(uid),
              //   });
              // }

              // if (facility === "pharmacy") {
              //   batch.set(locationsRef, {
              //     id: uid,
              //     title: location,
              //     lat: coords.lat,
              //     lng: coords.lng,
              //     type: 3,
              //     users: firebase.firestore.FieldValue.arrayUnion(uid),
              //   });

              //   batch.set(db.collection("emails").doc(locationId), {
              //     id: uid,
              //     to: "support@hlthdsk.com",
              //     message: {
              //       subject: "New Regional Facility Signup",
              //       html: `<p>A new user with the email ${email} has registered as a regional manager of ${location}.</p><p>If you'd like to remove this user, <a href="https://console.firebase.google.com/u/0/project/caremap2020/authentication/users">go to users in the Firebase Console</a>, search for the email address and delete the account.</p>`,
              //     },
              //   });
              // }
            }

            batch
              .commit()
              .then(() => {
                setRedirect(true);
                LinkedInTag.track(2177722); // Track purchase with LinkedIn analytics
              })
              .catch((error) => {
                setLoading(false);
                setFirebaseErrors(error.message);
                user.delete();
              });
          })
          .catch((error) => {
            setLoading(false);
            setFirebaseErrors(error.message);
            user.delete();
          });
      })
      .catch((error) => {
        setLoading(false);
        setFirebaseErrors(error.message);
      });
  };

  useEffect(() => {
    LinkedInTag.init(1439474);
  });

  const { user } = useContext(AuthContext);

  if (user && !pageLoaded) {
    return <Navigate to="/dashboard" />;
  }

  if (redirect) {
    return <Navigate to="/dashboard" />;
  }

  return user !== null && !pageLoaded ? (
    <Loading />
  ) : (
    <PageWrapper>
      <VideoWrapper $watchLevel={watchLevel}>
        <img src={patientVideo} />
      </VideoWrapper>

      <LogoLarge />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form1 register={register} errors={errors} />
        <FormAccessLevel
          register={register}
          unregister={unregister}
          setHospitalId={setHospitalId}
          setCoords={setCoords}
          setAddress={setAddress}
          setValue={setValue}
          watch={watch}
          errors={errors}
          control={control}
        />

        {firebaseErrors && <Error>{firebaseErrors}</Error>}

        <Text $center>
          By checking each box and continuing, you agree that you have read and
          agree to our:
        </Text>

        <Checkbox
          name="terms"
          register={register}
          rules={{
            required: "You must agree to the terms and services.",
          }}
          label={
            <a href="/privacy-policy" target="_blank">
              Privacy Policy
            </a>
          }
          errors={errors}
        />

        <Checkbox
          name="privacy"
          register={register}
          rules={{
            required: "You must agree to the terms and services.",
          }}
          label={
            <a href="/terms-of-use" target="_blank">
              Terms and Services
            </a>
          }
          errors={errors}
        />

        {/* <Checkbox
          name="baa"
          register={register}
          rules={{
            required: "You must agree to the Business Associate Agreement.",
          }}
          label={
            <a href="/baa" target="_blank">
              Business Associate Agreement
            </a>
          }
          errors={errors}
        /> */}

        <Button type="submit" $secondary disabled={loading}>
          {!loading ? "Sign Up" : <Loading />}
        </Button>
      </Form>
      <Text $center>
        Already have an account? <Link to="/login">Log in</Link>
      </Text>
      <TextAnchor $center href="https://MD3C.com/customize">
        Promote Your Organization First (Learn More)
      </TextAnchor>
      <TextAnchor $center href="/">
        Return to Map
      </TextAnchor>
    </PageWrapper>
  );
};

export default Register;
