import React from "react";
import InputField from "../styled/InputField";
import Error from "../styled/Error";

const Form1 = ({ register, errors }) => (
  <>
    <InputField
      label="Email"
      name="email"
      type="email"
      register={register}
      rules={{
        required: "Email is required.",
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: "Invalid email address.",
        },
      }}
    />
    {errors.email && <Error>{errors.email.message}</Error>}

    <InputField
      label="Password"
      name="password"
      type="password"
      register={register}
      rules={{
        required: "Password is required.",
        minLength: {
          value: 8,
          message: "Password must be at least 8 characters long.",
        },
        pattern: {
          value:
            /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g,
          message:
            "Password must contain uppercase and lowercase letters, and at least one number or special character.",
        },
      }}
    />
    {errors.password && <Error>{errors.password.message}</Error>}
  </>
);

export default Form1;
